import { CONTENT_TYPES } from 'utils/constants';

const PAGE_ALERT_BANNER = `
  fragment PageAlertBannerFragment on ${CONTENT_TYPES.PAGE_ALERT_BANNER}  {
    title
    bannerText
    linkUrl
    linkText
    bannerBackgroundColor
    textColor
  }
`;

export default PAGE_ALERT_BANNER;
