import { Flex, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { ReactElement } from 'react';

const PreviewBanner = (): ReactElement => {
  return (
    <Flex
      backgroundColor="status.alert"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      zIndex="999"
      justifyContent="space-between"
      alignItems="center"
      paddingX="5"
    >
      <Text color="white" fontWeight="bold" fontSize="lg">
        Preview Mode Enabled
      </Text>
      <NextLink href="/api/exit-preview" passHref>
        <Text color="white" fontWeight="bold" textDecoration="underline" fontSize="lg" as="a">
          Exit Preview
        </Text>
      </NextLink>
    </Flex>
  );
};

export default PreviewBanner;
