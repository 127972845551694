import { ReactElement } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  Box,
  Container,
  Flex,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { urlForwardSlashCleaner } from 'utils';
import { useStore } from 'store';
import { PRESCRIPTION_PROFILE_URL } from '../../utils/constants';

interface NavItem {
  route?: string;
  title?: string;
  textColor?: string;
}

interface NavItemParent extends NavItem {
  subcategoriesCollection?: {
    items?: Array<NavItem>;
  };
}

interface DesktopNavigationProps {
  navItems?: Array<NavItemParent>;
}

const DesktopNavigation = ({ navItems }: DesktopNavigationProps): ReactElement => {
  const { asPath } = useRouter();
  const { patientStore } = useStore();

  return (
    <Box backgroundColor="primary.dark">
      <Container maxW="container.xxl">
        <Flex>
          {navItems?.map(({ title, route, subcategoriesCollection }, index: number) => (
            <Popover
              key={title}
              gutter={0}
              placement="bottom-start"
              trigger="hover"
              isLazy
              id={`desktop-nav-${index}`}
            >
              {({ isOpen, onClose }) => {
                return (
                  <>
                    {route && (
                      <PopoverTrigger>
                        <Flex
                          alignItems="center"
                          padding="3"
                          backgroundColor={
                            isOpen || route === asPath ? 'primary.hover' : 'primary.dark'
                          }
                          _hover={{ cursor: 'pointer' }}
                        >
                          <Text color="white">{title}</Text>
                          <ChevronDownIcon color="white" w={7} h={7} />
                        </Flex>
                      </PopoverTrigger>
                    )}
                    <PopoverContent borderRadius="0">
                      <PopoverBody padding="0">
                        {subcategoriesCollection?.items?.map((collection) => {
                          if (!collection || !collection?.route) {
                            return null;
                          }
                          const { title, route, textColor } = collection;

                          const sendToLoggedInProfileScreen =
                            route === '/manage-prescriptions' && patientStore?.isLoggedIn
                              ? PRESCRIPTION_PROFILE_URL
                              : route;

                          return (
                            <NextLink
                              key={title}
                              href={urlForwardSlashCleaner(sendToLoggedInProfileScreen) ?? '/'}
                              passHref
                            >
                              <Link
                                display="block"
                                paddingY="3"
                                paddingLeft="3"
                                _hover={{ backgroundColor: 'shades.50' }}
                                onClick={onClose}
                              >
                                <Text color={textColor || 'primary.dark'}>{title}</Text>
                              </Link>
                            </NextLink>
                          );
                        })}
                      </PopoverBody>
                    </PopoverContent>
                  </>
                );
              }}
            </Popover>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default DesktopNavigation;
