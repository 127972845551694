import { Input } from '@chakra-ui/react';
import IMask from 'imask';
import React, { ChangeEvent, useState, ComponentProps, FocusEvent } from 'react';
// Internal

export interface MaskedDateInputProps extends ComponentProps<typeof Input> {}

export const dateRegexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/g;

const MaskedDateInput = (props: MaskedDateInputProps): JSX.Element => {
  // props
  const { onChange, onBlur, value, id, className, ...rest } = props;
  const [internalValue, setInternalValue] = useState<string | undefined>(undefined);

  const dateMask = IMask.createMask({
    mask: 'm{/}d{/}Y1Y2',
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2
      },
      Y1: {
        mask: IMask.MaskedRange,
        from: 19,
        to: 20,
        maxLength: 2
      },
      Y2: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 99,
        maxLength: 2
      }
    }
  });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const value: string | undefined = dateMask.resolve(e.target.value as string);

    e.target.value = value;

    setInternalValue(value);
    if (onChange) onChange(e);
  };

  const onBlurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (onBlur) onBlur(e as FocusEvent<HTMLInputElement, Element>);
  };

  const getMaskedValue = (value: string | undefined) => {
    if (!value) return value;

    dateMask.resolve(value as string);

    return dateMask.value;
  };

  return (
    <Input
      id={id ?? 'input_masked-phone'}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      value={getMaskedValue((value ?? internalValue) as string | undefined) ?? ''}
      maxLength={17}
      className={className}
      {...rest}
    />
  );
};

export default MaskedDateInput;
