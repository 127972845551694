import { CONTENT_TYPES } from 'utils/constants';

export const IMAGE_FRAGMENT = `
    fragment Image on ${CONTENT_TYPES.ASSET} {
        description
        title
        width
        height
        url
    }
`;

export const SUBCAT_COLLECTION_ITEM = `
    fragment subcategoriesCollectionItemFragment on ${CONTENT_TYPES.SUBCAT_COLLECTION_ITEM} {
        items {
            title
            route
            textColor
        }
    }
`;

export const NAV_CAT_ITEM = `
    ${SUBCAT_COLLECTION_ITEM}
    fragment NavCategoryItemFragment on ${CONTENT_TYPES.NAV_CATEGORY_ITEM} {
        title
        route
        subcategoriesCollection {
            ...subcategoriesCollectionItemFragment
        }
    }               
`;

export const IMAGE_BANNER = `
    fragment ImageBannerFragment on ${CONTENT_TYPES.IMAGE_BANNER} {
        title
        linkUrl
        sys {
            id
        }
        image {
            ...Image
        }
        mobileImage {
            ...Image
        } 
    }    
`;

export const BLOCK_QUOTE = `
    fragment BlockQuoteFragment on ${CONTENT_TYPES.BLOCK_QUOTE} {
        title
        blockQuoteText: text
        textColor
        sys {
            id
        }
    }    
`;

export const FEATURE_BANNER = `
    fragment FeatureBannerFragment on ${CONTENT_TYPES.FEATURE_BANNER} {
        title
        headline
        headlineColor
        bannerText
        textColor
        buttonText
        buttonColor
        buttonTextLink
        image {
            ...Image
        }
        sys {
            id
        }
    }    
`;

export const LOCATION_BANNER = `
    fragment LocationBannerFragment on ${CONTENT_TYPES.LOCATION_BANNER} {
        title
        headlineColor
        showLocationImage
        storeLocation: location {
            storeId
        }
        inPartnershipWith {
            ...Image
        }
        buttonText
        buttonTextLink
        buttonColor
    }
`;

export const TEXT_BANNER = `
fragment TextBannerFragment on ${CONTENT_TYPES.TEXT_BANNER} {
  title
  headline
  headlineColor
  image {
      ...Image
  }
  text
  buttonText
  buttonTextLink
  buttonColor
  textAlignment
}
`;
