import { Box } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';

import MDXComponent from 'components/Mdx';
import { ColumnsInPageData } from 'types/cms';

export interface ColumnsInPageProps extends ColumnsInPageData {
  isTravelClinic?: boolean;
}

const ColumnsInPage = ({
  column1,
  column2,
  column3,
  isTravelClinic
}: ColumnsInPageProps): ReactElement => {
  return (
    <Box
      d="flex"
      flexWrap="wrap"
      justifyContent="space-around"
      px={isTravelClinic ? { base: '0' } : { base: '0.25rem', sm: '2rem', md: '4rem' }}
      fontSize={{ base: '0.875rem' }}
      w="100%"
      mb="1.5rem"
    >
      <Box w={column3 ? '32%' : '48%'} minW="200px" flex={1} pr="4">
        <MDXComponent ml={6} w="100%">
          {column1}
        </MDXComponent>
      </Box>
      <Box w={column3 ? '32%' : '48%'} minW="200px" flex={1} pr="4">
        <MDXComponent ml={6} w="100%">
          {column2}
        </MDXComponent>
      </Box>
      {column3 && (
        <Box w="32%" minW="200px" flex={1} pr="4">
          <MDXComponent ml={6} w="100%">
            {column3}
          </MDXComponent>
        </Box>
      )}
    </Box>
  );
};

export default ColumnsInPage;
