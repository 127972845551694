import {
  Box,
  Text,
  Heading,
  UnorderedList,
  ListItem,
  Divider,
  OrderedList,
  ListIcon,
  Stack,
  Link
} from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import React, { ReactElement, ReactNode, ReactNodeArray } from 'react';
import NextLink from 'next/link';
import { BLOCKS, Block, Inline, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';

import { presetComponentProps } from 'theme';
import { PageBodyTextData } from 'types/cms';
import { Banner, BlockQuote, FeatureBanner } from '..';
import { CONTENT_TYPES } from 'utils/constants';
import PageBodyInsetBlock from './page-body-entries/PageBodyInsetBlock';
import RightArrow from 'public/assets/icons/right-arrow.svg';
import ColumnsInPage from './page-body-entries/ColumnsInPage';
import ImageGridInPage from './page-body-entries/ImageGridInPage';
import ButtonInPage from './page-body-entries/ButtonInPage';
import { UnknownObjectAny } from 'global.types';
import VideoWithThumbnail from './page-body-entries/VideoWithThumbnail';
import AssetHyperLink from './page-body-entries/AssetHyperLink';

export interface PageBodyTextProps {
  data?: PageBodyTextData;
  usedForInsetBlockOnly?: boolean;
  replaceBulletsWithArrows?: boolean;
  isTravelClinic?: boolean;
}

const PageBodyText = ({
  data,
  usedForInsetBlockOnly,
  replaceBulletsWithArrows,
  isTravelClinic
}: PageBodyTextProps): ReactElement | null => {
  // create an asset map
  const assetMap = new Map();
  if (data?.pageBodyText?.links?.assets?.block) {
    // loop through the assets and add them to the map
    for (const asset of data?.pageBodyText?.links?.assets?.block) {
      assetMap.set(asset?.sys?.id, asset);
    }
  }

  const entryMap = new Map();

  if (data?.pageBodyText?.links?.entries) {
    // create an entry map
    if (data?.pageBodyText?.links?.entries?.block) {
      // loop through the block linked entries and add them to the map
      for (const entry of data?.pageBodyText?.links?.entries?.block) {
        entryMap.set(entry?.sys?.id, entry);
      }
    }
    if (data?.pageBodyText?.links?.entries?.inline) {
      // loop through the inline linked entries and add them to the map
      for (const entry of data?.pageBodyText?.links?.entries?.inline) {
        entryMap.set(entry?.sys?.id, entry);
      }
    }
  }

  const options: Options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        return <AssetHyperLink id={node?.data?.target?.sys?.id}>{children}</AssetHyperLink>;
      },
      [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        return (
          <NextLink href={node?.data?.uri} passHref>
            <Link color="primary.default">{children}</Link>
          </NextLink>
        );
      },
      [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => <Text>{children}</Text>,
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.HEADING_2]: (_node: Block | Inline, children: ReactNode) => {
        if (usedForInsetBlockOnly) return null;

        // https://64labs.atlassian.net/browse/LDP-137: Need to insert an id for deep linking to a specific heading on the diabetes clinic page.
        const isDiabetesClinic =
          Array.isArray(children) &&
          children?.includes('How to book a visit to a Diabetes Management Clinic');

        const isBCCovidHeadline =
          Array.isArray(children) &&
          children?.includes('Asymptomatic COVID-19 Testing Clinics in BC');

        return (
          <Heading
            {...presetComponentProps?.h2}
            color="primary.medium"
            whiteSpace="pre-line"
            id={isDiabetesClinic || isBCCovidHeadline ? 'book' : ''}
          >
            {children}
          </Heading>
        );
      },
      [BLOCKS.HEADING_3]: (_node: Block | Inline, children: ReactNode) => (
        <Heading {...presetComponentProps.h3} color="default" whiteSpace="pre-line">
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (_node: Block | Inline, children: ReactNode) => (
        <Heading as="h4" whiteSpace="pre-line">
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (_node: Block | Inline, children: ReactNode) => (
        <Heading as="h5" whiteSpace="pre-line">
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_6]: (_node: Block | Inline, children: ReactNode) => (
        // All h6 tags are used to make small grayed out text.
        <Text whiteSpace="pre-line" fontSize="0.75rem" color="#858686">
          {children}
        </Text>
      ),
      [BLOCKS.HR]: () => <Divider my="2em" />,
      [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => {
        const filteredChildren = (
          Array.isArray(children) ? (children as ReactNodeArray) : undefined
        )?.filter((component: ReactNode) => component !== '');

        if (filteredChildren?.length === 0) return null;

        return (
          <Text
            whiteSpace="pre-line"
            fontSize={usedForInsetBlockOnly ? { base: '1rem', md: '1.125rem' } : undefined}
            mb="1rem !important"
          >
            {filteredChildren}
          </Text>
        );
      },
      [BLOCKS.QUOTE]: (node: Block | Inline | UnknownObjectAny) => {
        return <BlockQuote>{node?.content?.[0]?.content?.[0]?.value}</BlockQuote>;
      },
      [BLOCKS.OL_LIST]: (node: Block | Inline | UnknownObjectAny, children: ReactNode) => {
        // Used to determined h6 tags, all h6 tags are used to make small grayed out text.
        const firstNodeIsH6 = node?.content?.[0]?.content?.[0]?.nodeType === BLOCKS.HEADING_6;
        return (
          <OrderedList
            ml={
              usedForInsetBlockOnly && replaceBulletsWithArrows
                ? 0
                : { base: '1.5rem !important', md: '2rem !important' }
            }
            spacing={usedForInsetBlockOnly && replaceBulletsWithArrows ? 8 : 3}
            mt={
              (usedForInsetBlockOnly && !replaceBulletsWithArrows) || firstNodeIsH6
                ? '0 !important'
                : '1rem !important'
            }
            mb="1rem"
            fontSize={firstNodeIsH6 ? '0.75rem' : undefined}
            color={firstNodeIsH6 ? '#858686' : undefined}
          >
            {children}
          </OrderedList>
        );
      },
      [BLOCKS.UL_LIST]: (node: Block | Inline | UnknownObjectAny, children: ReactNode) => {
        // Used to determined h6 tags, all h6 tags are used to make small grayed out text.
        const firstNodeIsH6 = node?.content?.[0]?.content?.[0]?.nodeType === BLOCKS.HEADING_6;
        return (
          <UnorderedList
            ml={
              usedForInsetBlockOnly && replaceBulletsWithArrows
                ? 0
                : { base: '1.5rem !important', md: '2rem !important' }
            }
            spacing={usedForInsetBlockOnly && replaceBulletsWithArrows ? 2 : 1}
            mt={
              (usedForInsetBlockOnly && !replaceBulletsWithArrows) || firstNodeIsH6
                ? '0 !important'
                : '1rem !important'
            }
            mb={usedForInsetBlockOnly && replaceBulletsWithArrows ? '1rem !important' : 0}
            fontSize={firstNodeIsH6 ? '0.75rem' : undefined}
            color={firstNodeIsH6 ? '#858686' : undefined}
          >
            {children}
          </UnorderedList>
        );
      },
      [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: ReactNode) => {
        if (replaceBulletsWithArrows)
          return (
            <ListItem
              d="flex"
              flexDir="row"
              alignItems="flex-start"
              fontSize="1.25rem"
              maxW="90% !important"
            >
              <ListIcon
                as={RightArrow}
                fill="primary.default"
                w="1rem"
                h="auto"
                mt="0.5rem"
                mr="1rem"
              />
              {children}
            </ListItem>
          );

        return <ListItem>{children}</ListItem>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
        if (usedForInsetBlockOnly) return null;

        const assetData = assetMap?.get(node?.data?.target?.sys?.id);
        if (!assetData) return null;

        return (
          <Box d="flex" justifyContent="center" w="100%" pb="2.5rem">
            <Image src={assetData.url} alt={assetData.description} mx="auto" />
          </Box>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        if (usedForInsetBlockOnly) return null;

        const entryData: any = entryMap?.get(node?.data?.target?.sys?.id) || {};
        let RenderComponent;

        switch (entryData?.__typename) {
          case CONTENT_TYPES.COLUMNS_IN_PAGE:
            RenderComponent = () => (
              <Box>
                <ColumnsInPage {...entryData} isTravelClinic={isTravelClinic} />
              </Box>
            );
            break;
          case CONTENT_TYPES.BANNER_IN_PAGE:
            RenderComponent = () => <Banner {...entryData} inPageBanner />;
            break;
          case CONTENT_TYPES.VIDEO_WITH_THUMBNAIL:
            RenderComponent = () => <VideoWithThumbnail {...entryData} />;
            break;
          case CONTENT_TYPES.BUTTON_IN_PAGE:
            RenderComponent = () => {
              let desktopAlignmentPosition: 'center' | 'flex-start' | 'flex-end';

              switch (entryData?.buttonAlignment) {
                case 'Center':
                  desktopAlignmentPosition = 'center';
                  break;
                case 'Right':
                  desktopAlignmentPosition = 'flex-end';
                  break;
                default:
                  desktopAlignmentPosition = 'flex-start';
                  break;
              }

              return (
                <ButtonInPage
                  entryData={entryData}
                  desktopAlignmentPosition={desktopAlignmentPosition}
                />
              );
            };
            break;
          case CONTENT_TYPES.IMAGE_BANNER:
            RenderComponent = () => <Text color="red">ColumnsInPage Needs Built</Text>;
            break;
          case CONTENT_TYPES.IMAGE_GRID_IN_PAGE:
            RenderComponent = () => <ImageGridInPage data={entryData} />;
            break;
          case CONTENT_TYPES.PAGE_BODY_INSET_BLOCK:
            RenderComponent = () => <PageBodyInsetBlock {...entryData} />;
            break;
          case CONTENT_TYPES.FEATURE_BANNER:
            RenderComponent = () => (
              <FeatureBanner bannerText={entryData?.bannerText} {...entryData} />
            );
            break;
          default:
            RenderComponent = () => null;
            break;
        }

        return <RenderComponent />;
      }
    }
  };

  if (!data) return null;

  return (
    <Stack spacing={usedForInsetBlockOnly ? 4 : '0.5rem'}>
      {documentToReactComponents(data?.pageBodyText?.json, options)}
    </Stack>
  );
};

export default PageBodyText;
