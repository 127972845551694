import { UnknownObjectAny } from 'global.types';

export const graphQLErrorHandler = (
  queryName: string,
  errors: { message: string; locations: UnknownObjectAny[] }[] | undefined,
  query?: string
) => {
  if (errors && query) console.log(query);

  if (errors) {
    console.group('\x1b[31m', `**************** ${queryName} GQL ERRORS ************************`);
    console.error('');
    errors?.forEach((error: any, index: number) => {
      console.error('\x1b[31m', `Message: ${error?.message}`);
      console.error('\x1b[31m', `Locations: ${JSON.stringify(error?.locations)}`);
      console.error('');
    });
    console.error(`**************** ${queryName} GQL ERRORS END ************************`);
    console.groupEnd();
  }

  return;
};
