import { encodeQueryParams } from './util/query-utils';

export const makeDemandwareRequest = async (location: string, requestData = {}) => {
  // Temporarily use vercel api.
  const url = `https://london-drugs-api-tsorensen64labs-64labs.vercel.app/api/${location}?client_id=b119c173-07f8-4da0-9600-4eea285a8814`;
  // const url = `https://www.londondrugs.com/dw/shop/v18_8/${location}?client_id=b119c173-07f8-4da0-9600-4eea285a8814&${encodeQueryParams(
  //   requestData
  // )}`
  try {
    const result = await fetch(url).then((r) => r.json());
    return result;
  } catch (error) {
    return undefined;
  }
};
