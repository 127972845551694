import { ReactElement } from 'react';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import MDXComponent from 'components/Mdx';
import { ImageCMSData } from 'types/cms';
import ProxiedImage from 'components/common/ProxiedImage';

export interface FeatureSplitProps {
  buttonColor?: string;
  buttonText?: string;
  buttonTextLink?: string;
  headline?: string;
  headlineColor?: string;
  image: ImageCMSData;
  // True = left, false = right
  imageLocation: boolean;
  text?: string;
  textColor?: string;
  title: string;
}

const FeatureSplit = ({
  buttonColor,
  buttonText,
  buttonTextLink,
  headline,
  headlineColor,
  image,
  imageLocation,
  text,
  textColor
}: FeatureSplitProps): ReactElement => (
  <Flex width="100%" direction={{ base: 'column', md: !!imageLocation ? 'row' : 'row-reverse' }}>
    {image && (
      <Box flex="0 0 40%" marginBottom={{ base: '5', md: '0' }}>
        <ProxiedImage
          className="feature-image"
          src={image.url}
          width={image.width}
          height={image.height}
          alt={image.title}
          layout="responsive"
        />
      </Box>
    )}
    <Stack
      spacing={4}
      flex={1}
      marginLeft={{ base: '0', md: !!imageLocation ? '10' : '0', xl: !!imageLocation ? '20' : '0' }}
      marginRight={{
        base: '0',
        md: !!imageLocation ? '0' : '10',
        xl: !!imageLocation ? '0' : '20'
      }}
      justifyContent="center"
      paddingLeft={{ base: '0', xl: !!imageLocation ? '0' : '8rem' }}
      paddingRight={{ base: '0', xl: !!imageLocation ? '8rem' : '0' }}
    >
      {headline && (
        <Text
          fontWeight="bold"
          fontSize={{ base: 'xl', lg: '2xl' }}
          color={headlineColor || 'primary.default'}
        >
          {headline}
        </Text>
      )}
      {text && (
        <MDXComponent fontSize={{ base: 'sm', md: 'md' }} color={textColor || 'black'}>
          {text}
        </MDXComponent>
      )}
      {buttonText && buttonTextLink && (
        <NextLink href={buttonTextLink} passHref>
          <Button
            variant="ld"
            as="a"
            width="fit-content"
            backgroundColor={buttonColor || 'primary.default'}
          >
            {buttonText}
          </Button>
        </NextLink>
      )}
      {!buttonText && buttonTextLink && (
        <NextLink href={buttonTextLink} passHref>
          <Box as="a" alignSelf="flex-end" aria-label="Forward arrow icon">
            <ArrowForwardIcon width="6" height="6" />
          </Box>
        </NextLink>
      )}
    </Stack>
  </Flex>
);

export default FeatureSplit;
