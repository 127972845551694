import NextLink from 'next/link';
import { Box, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';

import ProxiedImage from 'components/common/ProxiedImage';
import { HealthProductCMSData } from 'types/cms';

// Needs CMS data on a page to complete
const HealthProduct = ({ title, linkUrl, image }: HealthProductCMSData): ReactElement | null => {
  if (!image?.url || !linkUrl) {
    return null;
  }
  return (
    <NextLink href={linkUrl} passHref>
      <Stack
        spacing="2"
        as="a"
        paddingY="3"
        paddingX="4"
        border="1px solid"
        borderColor="shades.100"
        alignItems="center"
        justifyContent="center"
        borderRadius="lg"
        minHeight={{ base: '150px', md: '200px' }}
        _hover={{ backgroundColor: 'blackAlpha.50' }}
      >
        <Box>
          <ProxiedImage src={image?.url} width={image?.width} height={image?.height} alt={title} />
        </Box>
        <Text textAlign="center" fontSize={{ base: 'sm', md: 'md' }} fontWeight="bold">
          {title}
        </Text>
      </Stack>
    </NextLink>
  );
};

export default HealthProduct;
