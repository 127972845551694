import { NAV_CAT_ITEM, SUBCAT_COLLECTION_ITEM } from 'graphql/fragments';
import { CONTENT_TYPES } from 'utils/constants';

export const globalHeaderBannerQuery = `
    {
        list(id: "30yB2eQYVB8mqtTc6xQeME") {
            title
            itemCollection {
                items {
                    ...on ${CONTENT_TYPES.GLOBAL_HEADER_BANNER} {
                        title
                        bannerText
                        linkText
                        linkUrl
                        bannerBackgroundColor
                    }
                }
            }
        }
    }
`;

export const mainNavigationQuery = `
    ${NAV_CAT_ITEM}
    {
        list(id: "1z4rvjSzmYz8J8Vreq2KSP") {
            title
            itemCollection {
                items {
                    ...on ${CONTENT_TYPES.NAV_CATEGORY_ITEM} {
                        ...NavCategoryItemFragment
                    }
                }
            }
        }
    }
`;

export const footerNavigationQuery = `
     ${NAV_CAT_ITEM}
    {
        list(id: "2YmGVtlp0loqmJjYWhrgeP") {
            title
            itemCollection {
                items {
                    ...on ${CONTENT_TYPES.NAV_CATEGORY_ITEM} {
                        ...NavCategoryItemFragment
                    }
                }
            }
        }
    }
`;

export const footerComplianceNavigationQuery = `
     ${SUBCAT_COLLECTION_ITEM}
    {
        navCategoryItem(id: "1Yb1MSF1HC49DipIkbqJPR") {
            title
            subcategoriesCollection {
                ...subcategoriesCollectionItemFragment
            }
        }
    }
`;
