import React, { ComponentProps, ReactElement } from 'react';
import { default as NextLink } from 'next/link';
import { Link } from '@chakra-ui/layout';

export interface LDLinkProps extends Omit<ComponentProps<typeof Link>, 'href' | 'children'> {
  linkText?: string;
  href: string;
  children?: JSX.Element;
}

const LDLink = ({ href, children, linkText, ...rest }: LDLinkProps): ReactElement => {
  const isExternalTest = (href: string) => {
    if (href.startsWith('/')) return false;

    return true;
  };

  const child = () => {
    if (linkText) return linkText;

    if (children) return children;
  };

  return isExternalTest(href) ? (
    <Link
      {...rest}
      href={href}
      _hover={rest?._hover ?? { color: 'primary.default', textDecoration: 'underline' }}
    >
      {child()}
    </Link>
  ) : (
    <NextLink href={href} passHref>
      <Link
        _hover={rest?._hover ?? { color: 'primary.default', textDecoration: 'underline' }}
        {...rest}
      >
        {child()}
      </Link>
    </NextLink>
  );
};

export default LDLink;
