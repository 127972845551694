import { ReactElement, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';

const SearchBar = (): ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { control, handleSubmit } = useForm();
  const { push } = useRouter();
  const onSubmit = async ({ query }: { query: string }) => {
    if (!query) {
      return;
    }
    push(`/search?q=${query}`);
    // blur the input when submitting - this does not happen automatically if the user hits the enter key to submit
    inputRef?.current?.blur?.();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} action=".">
      <Controller
        control={control}
        name="query"
        render={({ field: { onChange, onBlur, value, name } }) => (
          <InputGroup>
            <Input
              ref={inputRef}
              backgroundColor="white"
              placeholder="Search Pharmacy"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="search"
              aria-label="Search"
            />
            <InputRightElement as="button" type="submit" aria-label="Search">
              <SearchIcon />
            </InputRightElement>
          </InputGroup>
        )}
      />
    </form>
  );
};

export default SearchBar;
