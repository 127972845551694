import { Box } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import RightArrow from 'public/assets/icons/right-arrow.svg';
import { HeaderOverlayButtonData } from 'types/cms';
import LDThemeButton from 'components/common/LDThemeButton';
import NextLink from 'next/link';
import { colorExtractor } from 'utils';
import { GTMClickEvent } from 'utils/gtmHelpers';
import { useRouter } from 'next/router';

export interface HeaderOverlayButtonProps {
  data: HeaderOverlayButtonData[];
}

const HeaderOverlayButton = ({ data }: HeaderOverlayButtonProps): ReactElement => {
  const buttons = data && (data?.length === 3 ? data?.slice(0, 2) : data?.slice(0, 4));
  const { asPath } = useRouter();

  return (
    <Box
      d="grid"
      gridTemplateColumns={
        buttons?.length > 1 ? { base: '100%', sm: 'auto', md: 'repeat(2, 1fr)' } : { base: '100%' }
      }
      gridTemplateRows={buttons?.length > 1 ? { base: 'auto' } : { base: '100%' }}
      gridGap={{ base: '0.5rem', lg: '1rem' }}
      h="auto"
      p={{ base: '0', sm: '1rem', lg: '1.5rem' }}
      boxShadow={{ md: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
      borderRadius={{ md: '0.625rem' }}
      bgColor="white"
      w={{ base: '100%', sm: '90%', md: '80%', lg: '75%' }}
      maxW={buttons?.length > 1 ? '50rem' : '24rem'}
      mx={{ base: 'auto' }}
    >
      {buttons?.map(({ buttonColor, buttonText, buttonTextLink }, index) => {
        return (
          <Box
            key={`header-overlay-button-${index}`}
            w="100%"
            minH={{ base: '5rem', lg: '5.5rem' }}
            maxH={{ base: '5rem', lg: '100%' }}
          >
            <NextLink href={buttonTextLink ?? '/'} passHref>
              <LDThemeButton
                as="a"
                borderRadius="md"
                w="100%"
                h="100%"
                px={{ base: '1.5rem', lg: '2rem' }}
                fontSize={{ base: 'md', lg: 'xl' }}
                lineHeight="1.2"
                justifyContent="space-between"
                bgColor={colorExtractor(buttonColor, 'primary.default')}
                rightIcon={<RightArrow height="100%" width="1.25rem" fill="currentColor" />}
                onClick={() => {
                  GTMClickEvent(buttonText, asPath);
                }}
              >
                <Box as="span" whiteSpace="normal" maxW={{ lg: '90%' }}>
                  {buttonText}
                </Box>
              </LDThemeButton>
            </NextLink>
          </Box>
        );
      })}
    </Box>
  );
};

export default HeaderOverlayButton;
