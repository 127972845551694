import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { ReactElement } from 'react';

import { TextBannerWithModalCMSData } from 'types/cms';
import { PageBodyText } from '.';
import LDThemeButton from 'components/common/LDThemeButton';
import useModalPageViewEventHandler from 'hooks/useModalPageViewEventHandler';

const TextBannerWithModal = ({
  buttonText,
  buttonColor,
  image,
  headline,
  headlineColor,
  modalText
}: TextBannerWithModalCMSData): ReactElement => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  useModalPageViewEventHandler({
    modalIsOpen: isOpen,
    modalName: headline + '_TextBannerWithModal'
  });

  return (
    <Box
      padding="5"
      borderRadius="lg"
      boxShadow="0px 8px 20px rgba(0, 0, 0, 0.1)"
      height={{ base: '200px', md: '300px' }}
    >
      <Stack
        spacing={{ base: '4', md: '10' }}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Stack spacing="2" justifyContent="center" alignItems="center">
          {image && <Image src={image?.url} width="50px" height="50px" alt={headline} />}
          <Text
            fontSize="2xl"
            textAlign="center"
            fontWeight="bold"
            color={headlineColor || 'primary.default'}
          >
            {headline}
          </Text>
        </Stack>
        {buttonText ? (
          <LDThemeButton
            paddingY="2"
            fontWeight="bold"
            bgColor={buttonColor || 'primary.default'}
            onClick={onOpen}
          >
            {buttonText}
          </LDThemeButton>
        ) : (
          <Box as="button" onClick={onOpen}>
            <AddIcon width={6} height={6} />
          </Box>
        )}
      </Stack>
      <Modal isCentered onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent marginX="2">
          <ModalCloseButton />
          <ModalBody padding="8">
            <PageBodyText
              data={{ pageBodyText: { ...modalText, links: {} }, title: '', __typename: '' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TextBannerWithModal;
