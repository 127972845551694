import { UnknownObjectAny } from 'global.types';

export const collectFormData = ($: any, formIdent = false) => {
  let form: any;

  if (formIdent) {
    form = $(formIdent);
  } else {
    form = $('form');
  }

  let inputs: {
    inputs: { [key: string]: any } | undefined;
    radios: { [key: string]: any } | undefined;
    selects: { [key: string]: any } | undefined;
    checks: { [key: string]: any } | undefined;
    checksArray: { [key: string]: any } | undefined;
    textareaArray: { [key: string]: any } | undefined;
  } = {
    inputs: undefined,
    radios: undefined,
    selects: undefined,
    checks: undefined,
    checksArray: undefined,
    textareaArray: undefined
  };

  const inputArray: any = {};

  const radioArray: any = {};

  const selectArray: any = {};

  const textareaArray: any = {};

  const checkedObject: any = {};

  const checkedArray: any = {};

  // Radios

  const radioArrayNames: any[] = [];

  form.find('input:radio').each((i: any, el: any) => {
    const radio = $(el);
    radioArrayNames.push(radio.attr('name'));
  });

  const uniqueRadioNames = Array.from(new Set(radioArrayNames));

  uniqueRadioNames.forEach((el) => {
    const radio = form.find(`input[name="${el}"]`);
    $(radio).each((i: any, radioEl: any) => {
      if (!radioArray[el]) {
        radioArray[el] = [];
      }
      radioArray[el].push({
        value: $(radioEl).val(),
        dataVal: $(radioEl).data('value'),
        id: $(radioEl).attr('id'),
        label: $(radioEl).parent('label').text().trim(),
        explanation: $(radioEl).parents().eq(2).find('.radio-msg').text().trim(),
        checked: $(radioEl).attr('checked')
      });
    });
  });

  // Inputs

  form
    .find(
      'input:text, input[type="email"], input:password, input[type="hidden"], input[type="number"]'
    )
    .each((i: any, el: any) => {
      const input = $(el); // This is the jquery object of the input, do what you will

      const value = input.val();
      const name = input.attr('name');
      const id = input.attr('id');
      const label = form.find(`[for="${name}"]`).text() || form.find(`[for="${id}"]`).text();
      const type = input.attr('type');
      const maxlength = input.attr('maxlength');
      const required = input.attr('aria-required') || input.attr('data-val-required');
      const placeholder = input.attr('placeholder');

      inputArray[name] = {
        name,
        value,
        label,
        type,
        id,
        maxlength,
        required,
        placeholder
      };
    });

  // Selects
  form.find('select').each((i: any, el: any) => {
    const select = $(el); // This is the jquery object of the input, do what you will
    const value = select.val();
    const name = select.attr('name');
    const selectOptions = select.find('option');

    const options: any[] = [];

    selectOptions.each((i: any, el: any) => {
      options.push({
        text: $(el).text(),
        value: $(el).val()
      });
    });

    selectArray[name] = [];
    selectArray[name].push({
      selectedValue: value,
      id: select.attr('id'),
      name: select.attr('name'),
      label: form.find(`[for="${name}"]`).text(),
      options
    });
  });

  // Textareas
  form.find('textarea').each((i: any, el: any) => {
    const input = $(el); // This is the jquery object of the input, do what you will
    const value = input.val();
    const name = input.attr('name');

    textareaArray[name] = value;
  });

  // Checkboxes
  form.find('input[type="checkbox"]').each((i: any, el: any) => {
    const input = $(el); // This is the jquery object of the input, do what you will
    const value = input.val();
    const name = input.attr('name');
    const text = input.parents().eq(1).text().trim();

    if (!checkedArray[name]) {
      checkedArray[name] = [];
    }

    checkedArray[name].push({
      value,
      text,
      checked: input.checked
    });
  });

  // inputs.inputs = inputArray;
  // inputs.radios = radioArray;
  // inputs.selects = selectArray;
  // inputs.checks = checkedObject;
  // inputs.checksArray = checkedArray;
  // inputs.textareaArray = textareaArray;

  inputs = {
    inputs: inputArray,
    radios: radioArray,
    selects: selectArray,
    checks: checkedObject,
    checksArray: checkedArray,
    textareaArray: textareaArray
  };

  return inputs;
};

export const hasUpperCase = (str: string) => {
  return str.toLowerCase() !== str;
};

export const hasLowerCase = (str: string) => {
  return str.toUpperCase() !== str;
};

export const hasSpecialChars = (str: string) => {
  // according to LD specs
  const regex = /[@)([\]\\";:><]/g;
  return regex.test(str);
};

export const hasNumberLength = (str: string) => {
  return /\d/.test(str);
};

export const hasNonAlphabetChars = (str: string) => {
  return /[_\W]/.test(str);
};

const defaultRules: UnknownObjectAny = {
  pwLength: '8 or more characters',
  pwUpper: 'At least 1 capital letter',
  pwLower: 'At least 1 lowercase letter',
  pwChar: 'Cannot contain @ ( ) [ ] \\ " ; : > <',
  pwNumber: undefined,
  pwSymbol: undefined
};

export const getPasswordViolations = (password = '', rules = defaultRules) => {
  // Attempt to extract the number of required characters from `pwLength`
  const requiredCharacters = rules.pwLength && rules.pwLength.match(/(\d+)/);

  // Each property is `true` if that requirement is violated
  return {
    pwRequired: !password,
    pwLength: requiredCharacters && password.length < parseInt(requiredCharacters[1]),
    pwUpper: rules.pwUpper && !hasUpperCase(password),
    pwLower: rules.pwLower && !hasLowerCase(password),
    pwNumber: rules.pwNumber && !hasNumberLength(password),
    pwSymbol: rules.pwSymbol && !hasNonAlphabetChars(password),
    pwChar: rules.pwChar && hasSpecialChars(password)
  };
};
