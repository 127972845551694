import { Image } from '@chakra-ui/image';
import { Box, Heading, Link } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import NextLink from 'next/link';

import { ImageCMSData, ImageGridInPageData } from 'types/cms';
import { presetComponentProps } from 'theme';
import { useRouter } from 'next/router';
import { GTMClickEvent } from 'utils/gtmHelpers';

export interface ImageGridInPageProps {
  data: ImageGridInPageData;
}

const ImageGridInPage = ({ data }: ImageGridInPageProps): ReactElement => {
  const { asPath } = useRouter();
  const imageCollection = [...data?.imagesCollection?.items]?.splice(0, 6);

  const greaterThanThreeButNotFourAndSix =
    imageCollection?.length >= 3 && imageCollection?.length !== 4 && imageCollection?.length !== 6;

  const justifyContentSelector = () => {
    if (imageCollection?.length == 1) return 'center';
    if (imageCollection?.length >= 3 && imageCollection?.length !== 4) return 'space-evenly';
    return 'space-between';
  };

  const widthHandlers = {
    w: {
      base: '100%',
      sm: '47%',
      md: imageCollection?.length >= 3 && imageCollection?.length !== 4 ? '32%' : '47%'
    },
    minW: {
      base: '100%',
      small: greaterThanThreeButNotFourAndSix ? '32%' : '484px',
      md: greaterThanThreeButNotFourAndSix ? '32%' : '235px'
    },
    maxW: {
      base: '100%',
      small: greaterThanThreeButNotFourAndSix ? '32%' : '484px',
      md: greaterThanThreeButNotFourAndSix ? '32%' : '484px'
    }
  };

  const ImageGridInPageImage = (item: {
    image?: ImageCMSData | undefined;
    mobileImage?: ImageCMSData | undefined;
    title: string;
  }) => (
    <Box
      d="flex"
      justifyContent="center"
      alignItems="center"
      border="1px solid #C9C9CA"
      bgColor="white"
      px={{ base: 6, md: 10 }}
      py={{ base: 6, md: 8 }}
      h="auto"
      maxH={{ base: '130px', md: '163px' }}
      mb="1rem"
    >
      {item?.image?.url && (
        <Image
          src={item?.image?.url}
          d={item?.mobileImage ? { base: 'none', md: 'block' } : 'block'}
          alt={item?.image?.description}
          h="100%"
          w="auto"
        />
      )}
      {item?.mobileImage?.url && (
        <Image
          src={item?.mobileImage?.url}
          d={{ base: 'block', md: 'none' }}
          alt={item?.mobileImage?.description}
          h="100%"
          w="auto"
        />
      )}
    </Box>
  );

  return (
    <Box>
      {data?.headline && (
        <Heading
          {...presetComponentProps?.h3}
          color={data?.headlineColor ?? 'primary.default'}
          mb="1.5rem"
          textAlign="center"
        >
          {data?.headline}
        </Heading>
      )}
      <Box
        d="flex"
        flexWrap="wrap"
        justifyContent={justifyContentSelector()}
        alignItems="center"
        w="100%"
        mx="auto"
      >
        {imageCollection?.map((item) => {
          return item?.linkUrl ? (
            <Box {...widthHandlers} key={item.title}>
              <NextLink href={item?.linkUrl} passHref>
                <Link
                  width="auto"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item?.title}
                  onClick={() => {
                    GTMClickEvent(item?.title, asPath);
                  }}
                >
                  <ImageGridInPageImage {...item} />
                </Link>
              </NextLink>
            </Box>
          ) : (
            <Box {...widthHandlers} key={item.title}>
              <ImageGridInPageImage {...item} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ImageGridInPage;
