import {UnknownObjectAny} from 'global.types'

export function encodeQueryParams(obj: UnknownObjectAny) {
  const keyValuePairs = []
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    )
  }
  return keyValuePairs.join('&')
}
