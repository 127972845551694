import { ReactElement } from 'react';
import NextLink from 'next/link';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { ImageCMSData } from 'types/cms';
import LDThemeButton from 'components/common/LDThemeButton';
import ProxiedImage from 'components/common/ProxiedImage';

export interface FeatureBannerProps {
  bannerText: string;
  buttonColor?: string;
  buttonText?: string;
  buttonTextLink?: string;
  headline?: string;
  image?: ImageCMSData;
  textColor?: string;
}

const FeatureBanner = ({
  bannerText,
  buttonColor,
  buttonText,
  buttonTextLink,
  headline,
  image,
  textColor
}: FeatureBannerProps): ReactElement => (
  <Flex
    alignItems="center"
    justifyContent="center"
    border="1px solid #AFAFAF"
    paddingY={{ base: '5', lg: '10' }}
    paddingX="4"
    w="100%"
  >
    <Flex 
      direction={{ base: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      maxWidth={{ base: 'unset', lg: '80%' }}
    >
      {image && (
        <Box marginBottom={{ base: '3', md: '0' }} marginRight={{ base: '0', md: '8' }} flex={1}>
          <ProxiedImage
            className="feature-image"
            src={image.url}
            width={image.width}
            height={image.height}
            alt={image.title}
          />
        </Box>
      )}
      <Stack flex={2} spacing={{ base: '3', md: '3' }}>
        {headline && (
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" color="primary.default">
            {headline}
          </Text>
        )}
        <Text color={textColor ? textColor : 'black'}>{bannerText}</Text>
      </Stack>
      <Box pl={{ base: 0, md: 12 }} pt={{ base: 4, md: 0 }}>
        {buttonText && buttonTextLink && (
          <NextLink href={buttonTextLink} passHref>
            <LDThemeButton
              as="a"
              width="fit-content"
              bgColor={buttonColor || 'primary.default'}
              fontWeight="bold"
              alignSelf={{ base: 'center', md: 'initial' }}
            >
              {buttonText}
            </LDThemeButton>
          </NextLink>
        )}
        {!buttonText && buttonTextLink && (
          <NextLink href={buttonTextLink} passHref>
            <Box as="a" alignSelf="flex-end">
              <ArrowForwardIcon width="6" height="6" />
            </Box>
          </NextLink>
        )}
      </Box>
    </Flex>
  </Flex>
);

export default FeatureBanner;
