import { Box, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/layout';
import React, { ComponentProps, ReactElement } from 'react';
import { observer } from 'mobx-react';
import LDLink from './common/LDLink';
import LDNavAccordion from './LDNavAccordion';
import { commonGradients } from 'theme';
import { NavLinkData, LinksData } from 'types/cms';
import ProxiedImage from './common/ProxiedImage';
import { useStore } from 'store';

const Footer = (): ReactElement => {
  const { cmsStore } = useStore();

  const footerNavigation: NavLinkData[] = cmsStore?.footerNavigation?.footerNav;
  const footerComplianceNavigation: LinksData[] = cmsStore?.footerNavigation?.complianceNav;

  // Shared footer components start
  const SeparationBar = () => (
    <>
      <Box bgColor="accent.default" h="0.75em" w="100%" mb="0.25em" />
      <Box bgColor="primary.default" h={{ base: '2.75rem', md: '3.5em' }} w="100%" mb="0.25em" />
    </>
  );

  const FooterLogo = () => (
    <Flex
      w={{ base: '100%', lg: '18%' }}
      justifyContent="center"
      h={{ lg: '100%' }}
      mb={{ base: '2em', lg: '0' }}
    >
      <Box
        mt={{ lg: '0.65em' }}
        mr={{ lg: '4em' }}
        w={{ base: '100px', sm: '130px', md: '157.29px' }}
        h={{ base: '41.77px', sm: '54.3px', md: '65.69px' }}
        position="relative"
      >
        <ProxiedImage src="/LD-pharmacy-logo.png" layout="fill" alt="London Drugs logo" />
      </Box>
    </Flex>
  );

  const FooterComplianceLinks = () => {
    const commonLinkProps: Omit<ComponentProps<typeof LDLink>, 'href' | 'linkText'> = {
      target: '_blank',
      rel: 'noopener noreferrer',
      color: 'primary.default',
      whiteSpace: 'noWrap',
      fontWeight: 700,
      _hover: { color: 'primary.hover', textDecoration: 'underline' }
    };

    return (
      <Box w="100%" padding={{ base: '2em', md: 0 }}>
        <Flex
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          fontSize="0.8125rem"
          lineHeight="2"
          justifyContent={{ base: 'center', lg: 'flex-start' }}
          textAlign={{ base: 'center' }}
        >
          {footerComplianceNavigation?.map((linkData, index) => (
            <React.Fragment key={linkData?.title}>
              <LDLink
                linkText={linkData?.title}
                href={linkData?.route}
                color={linkData?.textColor ?? commonLinkProps?.color}
                {...commonLinkProps}
                mr={index === 1 ? { base: '1em', sm: '0' } : undefined}
              />
              {index === footerComplianceNavigation?.length - 1 ? null : (
                <Box
                  as="span"
                  mx="10px"
                  h="100%"
                  d={index === 1 ? { base: 'none', sm: 'block' } : undefined}
                >
                  |
                </Box>
              )}
            </React.Fragment>
          ))}
        </Flex>
        <Text fontSize="0.75em" lineHeight="2" textAlign={{ base: 'center', lg: 'left' }}>
          © {new Date()?.getFullYear()} London Drugs. All rights reserved.
        </Text>
      </Box>
    );
  };

  // Shared footer components end

  return (
    <>
      {/* Mobile footer 768px and down */}
      <Box w="100%" display={{ base: 'block', md: 'none' }}>
        <SeparationBar />
        <Box p="2em 0" bg={commonGradients?.whiteGrayToTransparent}>
          <FooterLogo />
          <LDNavAccordion data={footerNavigation} />
          <FooterComplianceLinks />
        </Box>
      </Box>
      {/* Desktop footer 769px and up */}
      <Box w="100%" display={{ base: 'none', md: 'block' }}>
        <SeparationBar />
        <Flex
          h="auto"
          justifyContent="center"
          p="2em 3em"
          flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          flexDir={{ base: 'column', lg: 'row' }}
          bg={commonGradients?.whiteGrayToTransparent}
        >
          <FooterLogo />
          <Box maxWidth="992px" w={{ base: '100%', lg: '82%' }} justifyContent="center">
            <Flex w="100%" height="auto" marginBottom="3em" justifyContent="space-between">
              {footerNavigation?.map((linkSection) => (
                <UnorderedList w="auto" listStyleType="none" m="0 1em 0 0" key={linkSection?.title}>
                  <ListItem
                    fontWeight={700}
                    color="primary.default"
                    textTransform="capitalize"
                    lineHeight="2em"
                    fontSize="1.125em"
                    key={linkSection?.title}
                  >
                    {linkSection?.title}
                  </ListItem>
                  {linkSection?.subcategoriesCollection?.items.map((link) => (
                    <ListItem textTransform="capitalize" key={link?.title}>
                      <LDLink
                        linkText={link?.title}
                        href={link?.route}
                        color={link?.textColor ?? 'default'}
                        lineHeight="2em"
                        fontSize="0.875em"
                      />
                    </ListItem>
                  ))}
                </UnorderedList>
              ))}
            </Flex>
            <FooterComplianceLinks />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default observer(Footer);
