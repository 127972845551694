import { Image } from '@chakra-ui/image';
import { Box, Heading, Text } from '@chakra-ui/layout';
import LDLink from 'components/common/LDLink';
import LDThemeButton from 'components/common/LDThemeButton';
import React, { ReactElement } from 'react';
import { presetComponentProps } from 'theme';
import { HeaderOverlayBannerData } from 'types/cms';
import RightArrow from '../../../public/assets/icons/right-arrow.svg';

export interface HeaderOverlayBannerProps {
  data: HeaderOverlayBannerData;
}

const HeaderOverlayBanner = ({ data }: HeaderOverlayBannerProps): ReactElement => {
  if (data?.imageOnlyBanner) {
    return (
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="0px 8px 20px rgba(0, 0, 0, 0.1)"
        mx="auto"
        bgColor="white"
        px={{ base: 10, md: 14 }}
        py={{ base: 10, md: 14 }}
        h="auto"
        w="90%"
        maxW="35.5625rem"
      >
        <Image src={data?.iconImage?.url} h="100%" w="auto" alt={data?.iconImage?.description} />
      </Box>
    );
  }

  return (
    <Box mx="auto" w={{ base: '100%', sm: '80%', lg: '60%' }} maxW="40rem">
      <LDLink
        href={data?.buttonLink ?? '/'}
        aria-label={data?.title}
        _hover={{ textDecoration: 'none', boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)' }}
        d="flex"
        flexDir="column"
        boxShadow="0px 8px 20px rgba(0, 0, 0, 0.1)"
        bgColor="white"
        borderRadius="0.625rem"
        pos="relative"
        px={{ base: '1.5rem', md: '3rem' }}
        py={{ base: '1.5rem', md: '2rem' }}
        w="100%"
        h={{ base: 'auto' }}
      >
        <>
          <Box d="flex" flex={1} flexDir="row" alignItems="center">
            {data?.iconImage?.url && (
              <Box w="20%" h="100%" d="flex" alignItems="center">
                <Image
                  src={data?.iconImage?.url}
                  alt={data?.iconImage?.description}
                  h="100%"
                  w="auto"
                />
              </Box>
            )}
            <Box w={data?.iconImage?.url ? '80%' : '100%'}>
              {data?.headline && (
                <Box d="flex" alignItems="center" h={data?.text ? '50%' : '100%'}>
                  <Heading
                    {...presetComponentProps?.h2}
                    color={data?.headlineColor ?? 'primary.default'}
                  >
                    {data?.headline}
                  </Heading>
                </Box>
              )}
              {data?.text && (
                <Box
                  d="flex"
                  alignItems="center"
                  h="auto"
                  pr={8}
                  mt={2}
                  fontSize={{ base: 'xs', md: 'lg' }}
                >
                  <Text>{data?.text}</Text>
                </Box>
              )}
            </Box>
          </Box>
          <Box d="flex" justifyContent="flex-end" w="100%">
            {data?.buttonText ? (
              <LDThemeButton
                as="div"
                bgColor={data?.buttonColor ?? 'primary.default'}
                fontSize={{ base: 'xs', md: 'lg' }}
                py={{ base: '0.8em' }}
                h={{ base: 'auto', md: '3rem' }}
                fontWeight="medium"
                _hover={{ bgColor: data?.buttonColor ?? 'primary.default' }}
              >
                {data?.buttonText}
              </LDThemeButton>
            ) : (
              <Box w={{ base: 4, md: '1.25rem' }} h={{ base: 4, md: 'auto' }}>
                <RightArrow width="100%" height="100%" fill="default" alt="Forward arrow" />
              </Box>
            )}
          </Box>
        </>
      </LDLink>
    </Box>
  );
};

export default HeaderOverlayBanner;
