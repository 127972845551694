import { UnknownObjectAny } from 'global.types';
import { flow, types as t } from 'mobx-state-tree';
import { getStoreInfo } from '../api';
import { searchStores } from '../api/stores';

export const LocationModel = t
  .model('Location', {
    id: t.identifier, // demandware id that maps to Amjay CorpStoreId
    name: t.string,
    address1: t.string,
    address2: t.string,
    city: t.string,
    province: t.string,
    postalCode: t.string,
    country: t.string,
    phone: t.string,
    pharmacyPhone: t.maybe(t.string),
    latitude: t.number,
    longitude: t.number,
    image: t.maybe(t.string),
    services: t.array(t.string),
    hours: t.array(t.frozen()),
    email: t.maybe(t.string),
    url: t.maybe(t.string)
  })
  .actions((self) => {
    return {
      fetchPickupDeliveryOptions: flow(function* fetchPharmacyInfo() {
        const pharmacyPhone = self?.pharmacyPhone?.replace(/\D/g, '');
        const result = yield getStoreInfo(pharmacyPhone);

        return parsePickupDeliveryOptions(result.Store.row[0].PickupDeliveryTimeslots);
      })
    };
  });

export const LocationStoreModel = t
  .model({
    locations: t.optional(t.map(LocationModel), {})
  })
  .views((self) => ({
    getAllPharmacyLocations() {
      const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
        (a: UnknownObjectAny, b: UnknownObjectAny) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      );

      return allPharmacies;
    },
    getAllPharmaciesOrderedByProvince() {
      const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
        (a: UnknownObjectAny, b: UnknownObjectAny) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      );

      const pharmaciesOrderedByProvince = {
        Alberta: allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'alberta'
        ),
        'British Columbia': allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'british columbia'
        ),
        Saskatchewan: allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'saskatchewan'
        ),
        Manitoba: allPharmacies.filter(
          (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'manitoba'
        )
      };

      return pharmaciesOrderedByProvince;
    },
    getProvinceNames() {
      const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
        (a: UnknownObjectAny, b: UnknownObjectAny) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      );

      const provinceNames = [
        ...new Set(
          allPharmacies
            ?.map((pharmacy: UnknownObjectAny) => pharmacy.province)
            .sort((a: UnknownObjectAny, b: UnknownObjectAny) => {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })
        )
      ];

      return provinceNames;
    },
    getLocatedPharmacies(value: string) {
      try {
        const allPharmacies: UnknownObjectAny[] = Object.values(self?.locations.toJSON()).sort(
          (a: UnknownObjectAny, b: UnknownObjectAny) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
        );

        if (!allPharmacies) {
          throw 'It appears something went wrong retrieving all the pharmacy options. Please come back later and try again.';
        }

        const pharmaciesOrderedByProvince: UnknownObjectAny = {
          Alberta: allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'alberta'
          ),
          'British Columbia': allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'british columbia'
          ),
          Saskatchewan: allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'saskatchewan'
          ),
          Manitoba: allPharmacies.filter(
            (pharmacy: UnknownObjectAny) => pharmacy?.province?.toLowerCase() === 'manitoba'
          )
        };

        const locatedPharmacies = [].concat.apply(
          [],
          Object.keys(pharmaciesOrderedByProvince)
            ?.map((key) => {
              return pharmaciesOrderedByProvince?.[key].filter(
                (pharmacy: UnknownObjectAny) =>
                  pharmacy?.postalCode?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()) ||
                  pharmacy?.city?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()) ||
                  pharmacy?.province?.toLocaleLowerCase().includes(value?.toLocaleLowerCase())
              );
            })
            .filter((provinceArray: UnknownObjectAny[] | []) => provinceArray.length > 0)
        );

        return locatedPharmacies;
      } catch (err) {
        console.error(err);
        return 'Error: ' + err;
      }
    },
    getSelectedStoreLocation(selectedPharmacyId: string) {
      return self?.locations.toJSON()?.[selectedPharmacyId];
    }
  }))
  .actions((self) => {
    return {
      fetchAllLocations: flow(function* fetchAllStores() {
        const locations = yield searchStores();

        for (const location of locations) {
          try {
            // Some locations have underscores in the id, remove these for the front-end
            if (/_$/.test(location.id)) {
              location.id = location.id.replace('_', '');
            }
            self.locations.set(location.id, parseLocation(location));
          } catch (error) {
            // Sentry.captureException(error)
            console.error('Error parsing store location');
            console.error(error);
          }
        }
      }),

      search: async (criteria: any) => {
        const locations = await searchStores(criteria);
        return locations.map(parseLocation);
      }
    };
  });

function parseLocation(location: any) {
  const servicesRegex = /Pharmacy,(\(\d{3}\)\s\d{3}-\d{4})/i;
  const servicesHtml = location?.c_serviceshtml;
  let phone = location.phone;
  if (typeof servicesHtml === 'string') {
    const match = servicesHtml.match(servicesRegex);
    if (typeof match?.[1] === 'string') {
      phone = match[1];
    }
  }
  return {
    ...location,
    province: location.state_code,
    postalCode: location.postal_code,
    country: location.country_code,
    services: location.c_services,
    hours: JSON.parse(location.store_hours)[0]?.storeHours,
    url: location.c_CustomStoreDetailsURL,
    phone
  };
}

function parsePickupDeliveryOptions(pdslots: any) {
  return pdslots
    .replace(/\r?\n|\r/g, '')
    .split('~')
    .filter((i: number) => i)
    .reduce((acc: any, slot: any) => {
      const [shortcodeLong, description, minutes, successMessage, repeatMessage] = slot.split('|');

      const [pdType, shortcode] = shortcodeLong.split('=');

      return {
        ...acc,
        [pdType]: [
          ...(acc[pdType] || []),
          {
            pdel: pdType,
            shortcode,
            description: sentenceCase(description.replace(/-/, ' - ')),
            minutes,
            successMessage,
            repeatMessage
          }
        ]
      };
    }, {});
}

function sentenceCase(str: string) {
  if (str === null || str === '') return false;
  else str = str.toString();

  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
