// URLs
export const PRESCRIPTION_PROFILE_URL = '/manage-prescriptions/prescription-profile';
export const PRESCRIPTION_DETAILS_URL = '/manage-prescriptions/prescription-detail';
export const QUICK_REFILL_URL = '/manage-prescriptions/quick-refill';
export const REQUEST_REFILL_URL = '/manage-prescriptions/prescription-refill-request';
export const REFILL_SUCCESS_URL = '/manage-prescriptions/prescription-refill-request-confirmation';
export const PRESCRIPTION_FAQS_URL = '/manage-prescriptions/faqs';
export const MEDICATION_REVIEW_URL = '/pharmacy-services/medication-review';
export const REFILL_PREFERENCES_URL = '/manage-prescriptions/patient-preferences';

export enum CONTENT_TYPES {
  ASSET = 'Asset',
  BANNER_IN_PAGE = 'BannerInPage',
  BLOCK_QUOTE = 'BlockQuote',
  BUTTON_IN_PAGE = 'ButtonInPage',
  VIDEO_WITH_THUMBNAIL = 'VideoWithThumbnail',
  CATEGORY_PAGE = 'CategoryPage',
  CATEGORY_PAGE_BODY_COLLECTION = 'CategoryPageBodyCollection',
  COLUMNS_IN_PAGE = 'ColumnsInPage',
  FAQ_ITEM = 'FaqItem',
  FEATURE_BANNER = 'FeatureBanner',
  FEATURE_SPLIT = 'FeatureSplitLeftright',
  GLOBAL_HEADER_BANNER = 'GlobalHeaderBanner',
  HEADER_OVERLAY_BANNER = 'HeaderOverlayBanner',
  HEADER_OVERLAY_BUTTON = 'HeaderOverlayButton',
  HEADER_OVERLAY_TILES = 'HeaderOverlayTiles',
  HEALTH_PRODUCT = 'HealthProduct',
  HOME_OVERLAY_SUBFEATURE = 'HomeOverlaySubFeature',
  HOME_HERO = 'HomeHero',
  IMAGE_BANNER = 'ImageBanner',
  IMAGE_GRID_IN_PAGE = 'ImageGridInPage',
  LIST = 'List',
  LOCATION_BANNER = 'LocationBanner',
  NAV_CATEGORY_ITEM = 'NavCategoryItem',
  PAGE = 'Page',
  PAGE_ALERT_BANNER = 'PageAlertBanner',
  PAGE_BODY_COLLECTION = 'PageBodyCollection',
  PAGE_BODY_INSET_BLOCK = 'PageBodyInsetBlock',
  PAGE_BODY_TEXT = 'PageBodyText',
  PHARMACIST = 'Pharmacist',
  SIDE_BY_SIDE_BANNERS = 'SideBySideTextBanners',
  SUBCAT_COLLECTION_ITEM = 'NavCategoryItemSubcategoriesCollection',
  TEXT_BANNER = 'TextBanner',
  TEXT_BANNER_WITH_MODAL = 'TextBannerWithModal',
  IMAGE_FULL_WIDTH_BANNER = 'ImageBannerFullWidth',
  IMAGE_BANNER_THREE_UP = 'ImageBanner2Up',
  PAGE_SUMMERY_BLOCK = 'PageSummaryBlock'
}

export const NEXT_PREVIEW_COOKIE = '__next_preview_data';

export const PROVINCE_MAP: { [key: string]: string } = {
  'British Columbia': 'BC',
  Alberta: 'AB',
  Manitoba: 'MB',
  Saskatchewan: 'SK'
};

export const TELEMEDICINE_CITIES = [
  'Abbotsford',
  'Burnaby',
  'Campbell River',
  'Chilliwack',
  'Coquitlam',
  'Courtenay',
  'Delta',
  'Duncan',
  'Gibsons',
  'Kamloops',
  'Kelowna',
  'Ladner',
  'Langley',
  'Maple Ridge',
  'Mission',
  'Nanaimo',
  'New Westminster',
  'North Vancouver',
  'Penticton',
  'Prince George',
  'Richmond',
  'Squamish',
  'Surrey',
  'Vancouver',
  'Vernon',
  'Victoria',
  'West Vancouver',
  'Westbank',
  'White Rock'
];
