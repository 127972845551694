import { types as t, flow } from 'mobx-state-tree';
import { makeDemandwareRequest } from '../api/demandware';
import { UnknownObjectAny } from 'global.types';

export const MiscStoreModel = t
  .model({
    'RX-Mobile-Terms': t.maybe(t.frozen()),
    PrivacyPolicy: t.maybe(t.frozen()),
    TermsConditions: t.maybe(t.frozen()),
    TERMS: t.maybe(t.frozen()),
    quickRefill: t.maybe(
      t.model('QuickRefill', {
        faqs: t.maybe(
          t.array(
            t.model('faq', {
              answer: t.string,
              question: t.string
            })
          )
        ),
        heading: t.maybe(t.string)
      })
    ),
    prescriptionProfile: t.maybe(
      t.model('PrescriptionProfile', {
        faqs: t.maybe(
          t.array(
            t.model('faq', {
              answer: t.string,
              question: t.string
            })
          )
        ),
        heading: t.maybe(t.string)
      })
    )
  })
  .actions((self: UnknownObjectAny) => {
    return {
      fetchTermsOrPrivacy: flow(function* fetchTermsOrPrivacy({ pageId, scrapeId }) {
        if (pageId) {
          const response = yield makeDemandwareRequest(`content/${pageId}`);
          self[pageId] = response;
        } else if (scrapeId) {
          // const response = yield extrasAPI.fetchTermsOrPrivacy(scrapeId)
          // self[scrapeId] = response
        }
      }),
      fetchQuickRefill: flow(function* fetchQuickRefill() {
        const response = yield makeDemandwareRequest('faqs/quick-refill');
        self.quickRefill = response;
      }),
      fetchPrescriptionProfile: flow(function* fetchPrescriptionProfile() {
        const response = yield makeDemandwareRequest('faqs/prescription-profile');
        self.prescriptionProfile = response;
      })
    };
  });
