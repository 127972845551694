import { extendTheme, theme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { ComponentProps } from 'react';
import { Heading } from '@chakra-ui/layout';

import { LightenDarkenColor } from 'utils';

export const commonGradients = {
  primaryDarkToPrimaryDefault: `linear-gradient(180deg, #2B6CB0 0%, #1E59AE 100%);`,
  grayToTransparent: `linear-gradient(180deg, #E8E8E8 0%, rgba(232, 232, 232, 0) 100%);`,
  whiteGrayToTransparent: `linear-gradient(180deg, #F2F2F2 0%, rgba(255, 255, 255, 0) 100%)`,
  transparentToWhiteGray: `linear-gradient(180deg, rgba(242, 242, 242, 0) 21.59%, #F2F2F2 109.23%);`,
  darkGreenToTransparent: ` linear-gradient(180deg, rgba(151, 188, 177, 0.3) 0%, rgba(151, 188, 177, 0) 100%)`
};

// Responsive component variants are not supported yet. The are on the roadmap. So this is a work around to keep constancy. (https://github.com/chakra-ui/chakra-ui/issues/1028)
export const presetComponentProps = {
  h1: {
    as: 'h1',
    fontWeight: { base: 'medium', sm: 'normal' },
    fontSize: { base: '2xl', sm: '3xl', md: '4xl', lg: '5xl' }
  } as ComponentProps<typeof Heading>,
  h2: {
    as: 'h2',
    mt: '1rem !important',
    mb: '0.5rem !important',
    fontWeight: 'semibold',
    fontSize: { base: 'lg', md: 'xl', lg: '2xl' }
  } as ComponentProps<typeof Heading>,
  h3: {
    as: 'h3',
    mb: '1rem',
    fontSize: { base: 'md' }
  } as ComponentProps<typeof Heading>,
  h4: {
    as: 'h4',
    fontSize: { base: 'sm' }
  } as ComponentProps<typeof Heading>,
  pageContainer: {
    // Please keep as rems, other places use these values for calculations (Example of other uses can be found in PageBodyInsetBlock)
    marginBottom: { base: '1.25rem', md: '2rem', lg: '2.5rem' },
    paddingX: { base: '1rem', md: '1.5rem', xxl: '0' },
    mx: 'auto'
  }
};

// Browser bases everything off of 16px
export const customBreakpoints = {
  base: '0',
  xs: '18.75em', // 300px
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em' // 1536px
};

const breakpoints = createBreakpoints(customBreakpoints);

const ldTheme = extendTheme({
  colors: {
    default: '#323233',
    primary: {
      hover: '#0d3561',
      dark: '#104783',
      darker: '#2a4365',
      medium: ' #1E59AE',
      default: '#005dab',
      vibrant: '#239dfb',
      light: '#7fbee7'
    },
    shades: {
      400: '#757575',
      300: '#979798',
      250: '#c4c4c4',
      200: '#c9c9ca',
      100: '#e1e1e3',
      50: '#f1f1f1'
    },
    status: {
      success: '#34aa44',
      alert: '#e73a26'
    },
    accent: {
      default: '#97bcb1',
      ldExtras: '#B6BD01'
    },
    muted: {
      success: '#eaf6ec',
      alert: '#fdf1f0',
      primaryLight: '#f2f9ff'
    }
  },
  breakpoints,
  fonts: {
    body: 'roboto, serif',
    heading: 'roboto, serif',
    mono: 'roboto, serif'
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24
    '3xl': '1.875rem', // 30px
    '4xl': '2rem', // 32px
    '5xl': '3rem', // 48px
    '6xl': '3.75rem', // Desktop Heading size
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  sizes: {
    ...theme.space,
    7.5: '1.875rem',
    container: {
      ...theme.sizes.container,
      standard: '900px',
      xxl: '1440px'
    }
  },
  components: {
    Text: {
      baseStyle: {
        color: 'default'
      }
    },
    Button: {
      variants: {
        ld: {
          bg: 'primary.default',
          color: 'white',
          _hover: { bg: LightenDarkenColor('#005dab', -10), _disabled: null }
        }
      }
    },
    Radio: {
      sizes: {
        xl: {
          control: { w: 7, h: 7 },
          label: { fontSize: 'xl' }
        }
      }
    }
  }
});

export default ldTheme;
