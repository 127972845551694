import { Box, Heading, Stack, Text } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import NextLink from 'next/link';

import LDThemeButton from 'components/common/LDThemeButton';
import ProxiedImage from 'components/common/ProxiedImage';
import { presetComponentProps } from 'theme';
import { PageSummaryBlockData } from 'types/cms';
import { urlForwardSlashCleaner } from 'utils';

export interface PageSummaryBlockProps extends PageSummaryBlockData {}

const PageSummaryBlock = ({
  blocksCollection,
  headlineColor,
  headline,
  buttonColor,
  buttonText
}: PageSummaryBlockProps): ReactElement => {
  const blockCollectionData = blocksCollection?.items;

  return (
    <Stack spacing="1rem" mb="1rem">
      {headline && (
        <Heading {...presetComponentProps?.h2} textAlign="center" w="100%" color={headlineColor}>
          {headline}
        </Heading>
      )}
      {blockCollectionData?.map(
        ({ headlineH1, shortDescription, iconThumbnail, parentSlug, slug }) => {
          const btnUrl = urlForwardSlashCleaner(`/${parentSlug}/${slug}`) ?? '/';

          return (
            <Box
              key={headlineH1}
              d="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              flexWrap="wrap"
              justifyContent="space-between"
              border="1px solid"
              borderColor="shades.100"
              p={{ base: '1rem', md: '1.75rem' }}
            >
              {iconThumbnail?.url && (
                <Box
                  d="flex"
                  flex={{ base: '1', md: '0 0 30%' }}
                  justifyContent="center"
                  h="100%"
                  mr={{ md: '1.75rem' }}
                  mb={{ base: '1rem', md: 0 }}
                >
                  <ProxiedImage
                    className="border-radius-10"
                    src={iconThumbnail?.url}
                    width={iconThumbnail?.width}
                    height={iconThumbnail?.height}
                    alt={iconThumbnail?.description}
                  />
                </Box>
              )}
              <Box d="flex" flexDirection="column" flex="1">
                {headlineH1 && (
                  <Heading {...presetComponentProps?.h3} color="primary.default" mb="0.5rem">
                    {headlineH1}
                  </Heading>
                )}
                {shortDescription && (
                  <Text fontSize="sm" mb="1rem">
                    {shortDescription}
                  </Text>
                )}
                {btnUrl && (
                  <Box d="flex" mt="auto" alignSelf={{ base: 'center', md: 'flex-end' }}>
                    <NextLink href={btnUrl} passHref>
                      <LDThemeButton as="a" bgColor={buttonColor ?? 'primary.default'}>
                        {buttonText ?? 'Learn More'}
                      </LDThemeButton>
                    </NextLink>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }
      )}
    </Stack>
  );
};

export default PageSummaryBlock;
