// import localStorage from '@react-native-community/async-storage'
// import * as SecureStore from 'expo-secure-store'

export const setItem = async (key: any, value: any) => {
  try {
    await localStorage?.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('ERROR STORING ITEM');
    console.error(error);
  }
};

export const getItem = async (key: any) => {
  try {
    const value = await localStorage?.getItem(key);
    const parsedValue = value && JSON.parse(value);
    return parsedValue;
  } catch (error) {
    console.error('ERROR GETTING ITEM');
    console.error(error);
  }
};

export const removeItem = async (key: any) => {
  try {
    await localStorage?.removeItem(key);
  } catch (error) {
    console.error('ERROR REMOVING ITEM');
    console.error(error);
  }
};

export const clear = (keys: any) => {
  return Promise.all([
    localStorage?.multiRemove(keys || ['NAVIGATION_STATE', 'APP_LOAD_STATUS', 'ROOT_STORE'])
  ]);
};
