import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';
import { useIdle } from 'react-use';
import Countdown from 'react-countdown';

import { useRouter } from 'next/router';

import { useStore } from 'store';
import { PRESCRIPTION_PROFILE_URL } from 'utils/constants';

const Inactivity = (): ReactElement => {
  const [now, setNow] = useState<number>(Date.now());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { patientStore } = useStore();
  const { push } = useRouter();
  const isIdle = useIdle(24e4); // 4 minutes

  useEffect(() => {
    if (patientStore?.isLoggedIn && isIdle && !isOpen) {
      onOpen();
      setNow(Date.now());
    }
  }, [patientStore?.isLoggedIn, isIdle, isOpen, onOpen]);

  const logout = () => {
    patientStore?.logout();
    push(PRESCRIPTION_PROFILE_URL);
    onClose();
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton top="1" right="2" />
          <ModalBody padding={{ base: '6', md: '5' }}>
            <Stack spacing="4">
              <Text fontWeight="semibold" fontSize="xl">
                Due to inactivity, your session will end soon.
              </Text>
              <Countdown
                date={now + 6e4}
                onComplete={logout}
                renderer={({ minutes, seconds }) => (
                  <Text fontSize="2xl" textAlign="center" fontWeight="semibold">
                    {`${minutes < 10 ? `0${minutes}` : minutes}:${
                      seconds < 10 ? `0${seconds}` : seconds
                    }`}
                  </Text>
                )}
              />
              <Flex
                justifyContent="center"
                gridGap="3"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Button onClick={logout} paddingX="7" color="primary.default">
                  Log Me Out
                </Button>
                <Button variant="ld" onClick={onClose} paddingX="7">
                  I'm Still Here
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(Inactivity);
