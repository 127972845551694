import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text
} from '@chakra-ui/react';
import { ReactElement } from 'react';

import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import MDXComponent from 'components/Mdx';

export interface FaqItemProps {
  question: string;
  answer: string;
  index: number;
}

const FaqItem = ({ question, answer, index }: FaqItemProps): ReactElement => {
  return (
    <Accordion allowToggle border="1px solid" borderColor="shades.100" borderRadius="lg">
      <AccordionItem border="none" id={`faq-item-${index}`} _hover={{}}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              paddingX={{ base: '1rem', md: '1.8rem' }}
              paddingY={{ base: '1rem', md: '1.8rem' }}
            >
              <Flex justifyContent="space-between" alignItems="center" width="100%">
                <Text
                  fontSize={{ base: 'md', md: 'lg' }}
                  fontWeight="semibold"
                  lineHeight="1.5"
                  textAlign="left"
                  pr={2.5}
                >
                  {question}
                </Text>
                {isExpanded ? (
                  <MinusIcon width={{ base: '3', md: '4' }} height={{ base: '3', md: '4' }} />
                ) : (
                  <AddIcon width={{ base: '3', md: '4' }} height={{ base: '3', md: '4' }} />
                )}
              </Flex>
            </AccordionButton>
            <AccordionPanel pt={5} m={{ base: '1rem', md: '1.7rem' }} backgroundColor="shades.50">
              <MDXComponent>{answer}</MDXComponent>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default FaqItem;
