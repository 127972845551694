import fetchContentfulData from 'api/contentful-fetch';
import { UnknownObjectAny } from 'global.types';
import React, { useEffect, useState } from 'react';
import { ReactNode } from 'react-markdown/lib/react-markdown';
import NextLink from 'next/link';
import { Link } from '@chakra-ui/react';
export interface AssetHyperLinkProps {
  id?: string;
  children?: ReactNode;
}

const AssetHyperLink = ({ id, children }: AssetHyperLinkProps): JSX.Element | null => {
  const [assetUrl, setAssetUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    let mounted = true;

    if (!mounted && !id) return;
    fetchContentfulData({
      query: `query {asset(id: "${id}") {url}}`
    }).then((res) => {
      setAssetUrl(((res as UnknownObjectAny)?.data?.asset?.url as string) ?? undefined);
    }) as UnknownObjectAny;

    return () => {
      mounted = false;
    };
  }, [id]);

  return (
    <>
      {assetUrl && (
        <NextLink href={assetUrl} passHref>
          <Link target="_blank" rel="noopener noreferrer" color="primary.default">
            {children}
          </Link>
        </NextLink>
      )}
    </>
  );
};

export default AssetHyperLink;
