import { Box, Stack } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { UnknownObjectAny } from 'global.types';
import { CONTENT_TYPES } from 'utils/constants';
import { ComponentGeneratorProps } from './ComponentGenerator';
import { FaqItem, HealthProduct, Pharmacist, TextBannerWithModal } from 'components/CMS';

export interface ListGeneratorProps extends UnknownObjectAny {
  itemCollection: {
    items: Array<ComponentGeneratorProps>;
  };
}

type ListType = CONTENT_TYPES.FAQ_ITEM | CONTENT_TYPES.HEALTH_PRODUCT | string;

const ListGenerator = ({ itemCollection }: ListGeneratorProps): ReactElement | null => {
  const listType: ListType = itemCollection?.items?.[0]?.__typename;

  switch (listType) {
    case CONTENT_TYPES.FAQ_ITEM:
      return (
        <Stack spacing="2.5" mb="1rem">
          {itemCollection?.items?.map((faqItem, index) => (
            <FaqItem
              key={`faq-item-${index}`}
              {...faqItem}
              index={index}
              question={faqItem.question}
              answer={faqItem.answer}
            />
          ))}
        </Stack>
      );
    case CONTENT_TYPES.PHARMACIST:
      return (
        <Stack spacing={{ base: '5', md: '10' }} alignItems="center">
          {itemCollection?.items?.map((pharmacist, index) => (
            <Pharmacist key={`pharmacist-${index}`} {...pharmacist} />
          ))}
        </Stack>
      );
    case CONTENT_TYPES.HEALTH_PRODUCT:
      return (
        <Box
          display="grid"
          gridTemplateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)'
          }}
          justifyContent="center"
          gridColumnGap="3"
          gridRowGap="3"
          maxWidth="100%"
        >
          {itemCollection?.items?.map((healthProduct, index) => (
            <HealthProduct key={`health-product-${index}`} {...healthProduct} />
          ))}
        </Box>
      );
    case CONTENT_TYPES.TEXT_BANNER_WITH_MODAL:
      return (
        <Stack spacing={{ base: '5', md: '10' }} alignItems="center">
          {itemCollection?.items?.map((textBannerWithModal, index) => (
            <TextBannerWithModal
              key={`text-banner-with-modal-${index}`}
              {...textBannerWithModal}
              headline={textBannerWithModal?.headline}
              modalText={textBannerWithModal?.modalText}
            />
          ))}
        </Stack>
      );
    default:
      return null;
  }
};

export default ListGenerator;
