import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { GTMPageViewEvent, GTMPageViewEventData } from 'utils/gtmHelpers';

const useModalPageViewEventHandler = ({
  modalName,
  modalIsOpen
}: {
  modalIsOpen: boolean;
  modalName: string;
}) => {
  const { asPath } = useRouter();

  const pageTitle =
    modalName
      ?.split(' ')
      .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
      .join('')
      .replace(/\s/g, '')
      .replace('Modal', '') + 'Modal';

  const pageInfo: GTMPageViewEventData = {
    page_url: asPath + `?${pageTitle}IsOpen=true`,
    page_title: pageTitle
  };

  useEffect(() => {
    let mounted = true;

    if (!mounted || !modalIsOpen) return;

    GTMPageViewEvent(pageInfo);

    return () => {
      mounted = false;
    };
  }, [modalIsOpen]);

  return null;
};

export default useModalPageViewEventHandler;
