import { UnknownObjectAny } from 'global.types';
import { flow, types as t } from 'mobx-state-tree';
import * as amjay from '../api';
import { LocationModel } from './location.model';
import { PrescriptionModel } from './prescription.model';

export const PatientPharmacyModel = t
  .model('PatientPharmacy', {
    id: t.identifier, // identifies the pharmacy via Amjay
    systemType: t.string,
    storePatientId: t.string,
    status: t.string,
    name: t.string,
    address: t.string,
    city: t.string,
    province: t.string,
    postalCode: t.string,
    country: t.string,
    phone: t.string,
    pickupDeliveryOptions: t.maybe(t.string),
    pickupDeliveryTimeslots: t.maybe(t.string),
    location: t.optional(t.safeReference(LocationModel), undefined),
    patientGroups: t.maybe(t.array(t.string)),
    prescriptions: t.map(PrescriptionModel)
  })
  .views((self) => ({
    get prescriptionCount() {
      return Array.from(self.prescriptions.values())?.length || 0;
    },
    filteredPrescriptionsList(
      filter:
        | 'On Order'
        | 'Last Filled (60 days)'
        | 'Last Filled (past year)'
        | 'On File'
        | 'Expired'
    ) {
      // https://64labs.atlassian.net/browse/LDRN-186
      // Sort the prescriptions by fillDate. We get them from the API in this order,
      // but they get auto-sorted to order by rxNumber because we transform them
      // into an object before passing data to mobx
      const initialPrescriptionList = Array.from(self.prescriptions.values()).sort((a, b) => {
        if (a?.fillDate && b?.fillDate) {
          return new Date(a.fillDate) < new Date(b.fillDate) ? 1 : -1;
        }
        return 1;
      });

      switch (filter) {
        case 'On Order':
          return initialPrescriptionList.filter(
            ({ stateStatus }) => stateStatus?.toLowerCase() === 'on order'
          );

        case 'Last Filled (60 days)':
          return initialPrescriptionList.filter(({ fillDate }) => {
            const today = new Date();
            const days60ago = new Date().setDate(today.getDate() - 60);
            // return fillDate - days60ago > 0;
          });

        case 'Last Filled (past year)':
          return initialPrescriptionList.filter(({ fillDate }) => {
            const today = new Date();
            const aYearAgo = new Date().setDate(today.getDate() - 365);
            // return fillDate - aYearAgo > 0;
          });

        case 'On File':
          return initialPrescriptionList.filter(
            ({ stateStatus }) => stateStatus?.toLowerCase() === 'on file'
          );

        case 'Expired':
          return initialPrescriptionList.filter(
            ({ stateStatus }) => stateStatus?.toLowerCase() === 'inactive/expired'
          );

        // All Prescriptions
        default:
          return initialPrescriptionList;
      }
    },
    getPickupDeliveryOptions() {
      return self?.location?.fetchPickupDeliveryOptions();
    }
  }));

export const PatientProfileModel = t.model('Patient', {
  id: t.identifier,
  key: t.string,
  pin: t.string,
  firstName: t.string,
  lastName: t.string,
  birthdate: t.maybe(t.string),
  address: t.maybe(t.string),
  city: t.maybe(t.string),
  province: t.maybe(t.string),
  postalCode: t.maybe(t.string),
  country: t.maybe(t.string),
  phone: t.maybe(t.string),
  email: t.maybe(t.string),
  contactPreference: t.maybe(t.string),
  pharmacies: t.map(PatientPharmacyModel),
  currentPharmacy: t.maybe(t.string)
});

export const PatientStoreModel = t
  .model({
    profile: t.maybe(PatientProfileModel),
    automaticLogout: t.maybe(t.boolean)
  })
  .views((self) => ({
    get isLoggedIn() {
      return !!self.profile;
    },
    get patientPharmacies() {
      if (self.profile) {
        return Array.from(self.profile.pharmacies?.values());
      }
      return [];
    },
    getPharmacy(id: string) {
      return self?.profile?.pharmacies?.get(id);
    },
    getPharmacyByStoreID(storeId: string) {
      if (!self?.profile?.pharmacies?.toJSON()) return;

      return Object.values(self?.profile?.pharmacies?.toJSON())
        .map((pharmacy: UnknownObjectAny) => pharmacy)
        .filter((pharmacy) => pharmacy.location === storeId)?.[0];
    },
    getPrescription(pharmacyID: string, rxID: string) {
      return self?.profile?.pharmacies?.get(pharmacyID)?.prescriptions?.get(rxID);
    },
    getPharmacyLocations(pharmacyID: string) {
      return self?.profile?.pharmacies?.get(pharmacyID)?.location;
    }
  }))
  .actions((self) => {
    return {
      logout() {
        self.profile = undefined;
      },

      setAutomaticLogout(value: any) {
        self.automaticLogout = value;
      },

      setCurrentPharmacy(value: any) {
        if (self?.profile) {
          self.profile.currentPharmacy = value;
        }
      },

      refetchCurrentStore: flow(function* refetchCurrentStore() {
        const patient = self.profile;
        if (patient) {
          const { key, pin, currentPharmacy, phone } = patient;
          const res = yield amjay.getPrescriptions(key, pin, currentPharmacy);

          if (currentPharmacy) {
            // @ts-expect-error TS does not trust that the current pharmacy exists on the patient. The only place this method is called, it has to exist to reach this function.
            patient.pharmacies.get(currentPharmacy).prescriptions = res?.Profile?.row?.reduce(
              (acc: any, prescription: any) => {
                if (!prescription.Rxnumber) {
                  return acc;
                }

                return {
                  ...acc,
                  [prescription.Rxnumber]: {
                    pharmacyId: currentPharmacy,
                    patientKey: key,
                    patientPin: pin,
                    patientPhone: res.Patient.row[0].phone
                      ? res.Patient.row[0].phone
                          .slice(res.Patient.row[0].phone.indexOf(':') + 1)
                          .replace(/"/g, '')
                          .split(/\s*ext/i)[0]
                          .split(',')[0]
                      : phone,
                    id: prescription.Rxnumber,
                    rxid: prescription.Rxnumber,
                    din: prescription.din,
                    name: prescription.Drugname,
                    tradeName: prescription.TradeName,
                    genericName: prescription.GenericName,
                    fillDate: prescription.Filldate,
                    fillQuantity: prescription.FillQuantity,
                    strength: prescription.DrugStrength,
                    quantityRemaining: prescription.QuantityRemaining,
                    quantityAuthorized: prescription.QuantityAuthorized,
                    image: prescription.drugpic,
                    doctorName: prescription.Doctor,
                    instructions: prescription.Instructions,
                    refillable: prescription.Refillable !== 'N',
                    refillStatus: prescription.Reason,
                    status: prescription.Status,
                    otherStatus: prescription.otherstatus,
                    daysSupply: prescription.dayssupply,
                    repeats: prescription.Repeats,
                    expires: prescription.rxexpiry,
                    counsel: [],
                    form: prescription.drugform,
                    stateStatus: formatStateStatus(prescription.State),
                    ...formatDrugForm(prescription.drugform)
                  }
                };
              },
              {}
            );
          }

          self.profile = patient;
        }
      }),

      updatePassword: flow(function* updatePassword(values) {
        const { id, pin, password, dob } = values;
        const month = dob.split('/')[0];
        const day = dob.split('/')[1];
        const year = dob.split('/')[2];
        const res = yield amjay.updatePassword(id, pin, password, `${year}-${month}-${day}`);
        if (res?.row?.row[0]?.Error) {
          return {
            hasError: true,
            error: res?.row?.row?.[0].Error
          };
        }
        // self.login(id, password);
      }),

      login: flow(function* login(id, pin) {
        const { patientData, storeData, hasErrors, errors } = yield amjay.patientLogin(id, pin);

        if (hasErrors) {
          return { hasErrors, errors };
        }

        if (patientData?.PasswordChange === '0') {
          return {
            passwordUpdateRequired: true,
            firstName: patientData?.Firstname,
            lastName: patientData?.Lastname
          };
        }

        const patient = {
          id: patientData?.Id,
          key: patientData?.PatientKey,
          pin: patientData?.Pin,
          firstName: patientData?.Firstname,
          lastName: patientData?.Lastname,
          birthdate: patientData?.Birthdate,
          address: patientData?.Address,
          city: patientData?.City,
          province: patientData?.Province,
          postalCode: patientData?.Postal,
          country: patientData?.Country,
          phone: patientData?.Phone,
          email: patientData?.Email,
          contactPreference: patientData?.contactpreference,
          pharmacies: storeData.reduce((acc: any, store: any) => {
            return {
              ...acc,
              [store.StoreIdentifier]: {
                id: store.StoreIdentifier,
                systemType: store.systemtype,
                storePatientId: store.storepatientid,
                status: store.Status,
                name: store.Status,
                address: store.storeAddress,
                city: store.storeCity,
                province: store.storeProvince,
                postalCode: store.storePostal,
                country: store.Country,
                phone: store.Phone
              }
            };
          }, {}),
          currentPharmacy: patientData?.currentPharmacy
        };

        for (const store of storeData) {
          const res = yield amjay.getPrescriptions(patient.key, patient.pin, store.StoreIdentifier);

          patient.pharmacies[store.StoreIdentifier].location = res?.Store?.row?.[0].CorpStoreId;

          // This is a hack to fix https://64labs.atlassian.net/browse/LDP-214 - the Amjay store data has an underscore in the ID causing a mismatch with the SFCC store data
          if (res.Store.row[0].CorpStoreId === '751_') {
            patient.pharmacies[store.StoreIdentifier].location = '751';
          }

          patient.pharmacies[store.StoreIdentifier].patientGroups =
            res.Patient.row[0]?.PatGroups?.split(',');

          patient.pharmacies[store.StoreIdentifier].prescriptions = res.Profile.row.reduce(
            (acc: any, prescription: any) => {
              if (!prescription.Rxnumber) {
                return acc;
              }

              return {
                ...acc,
                [prescription.Rxnumber]: {
                  pharmacyId: store.StoreIdentifier,
                  patientKey: patient.key,
                  patientPin: patient.pin,
                  patientPhone: res.Patient.row[0].phone
                    ? res.Patient.row[0].phone
                        .slice(res.Patient.row[0].phone.indexOf(':') + 1)
                        .replace(/"/g, '')
                        .split(/\s*ext/i)[0]
                        .split(',')[0]
                    : patient.phone,
                  id: prescription.Rxnumber,
                  rxid: prescription.Rxnumber,
                  din: prescription.din,
                  name: prescription.Drugname,
                  tradeName: prescription.TradeName,
                  genericName: prescription.GenericName,
                  fillDate: prescription.Filldate,
                  fillQuantity: prescription.FillQuantity,
                  strength: prescription.DrugStrength,
                  quantityRemaining: prescription.QuantityRemaining,
                  quantityAuthorized: prescription.QuantityAuthorized,
                  image: prescription.drugpic,
                  doctorName: prescription.Doctor,
                  instructions: prescription.Instructions,
                  refillable: prescription.Refillable !== 'N',
                  refillStatus: prescription.Reason,
                  status: prescription.Status,
                  otherStatus: prescription.otherstatus,
                  daysSupply: prescription.dayssupply,
                  repeats: prescription.Repeats,
                  expires: prescription.rxexpiry,
                  counsel: [],
                  form: prescription.drugform,
                  stateStatus: formatStateStatus(prescription.State),
                  ...formatDrugForm(prescription.drugform)
                }
              };
            },
            {}
          );
        }

        self.profile = patient;
        return patient;
      })
    };
  });

// Outputs the drugform in both short and long form
function formatDrugForm(drugform: any) {
  if (!drugform) {
    return {};
  }
  // Extract the short form of the drug (eg. "TAB")
  const drugShortFormMatch = drugform.match(/^([A-Z]+)/);
  const shortForm = drugShortFormMatch ? drugShortFormMatch[0] : 'UNIT';
  // Extract the long form of the drug (eg. "Tablet")
  const drugLongFormMatch = drugform.match(/\(([^)]+)/);
  const longForm = drugLongFormMatch ? `[${drugLongFormMatch[1]}]` : drugform;

  return { shortForm, longForm };
}

function formatStateStatus(state: string) {
  if (!state) {
    return '';
  }
  let stateStatus;

  switch (state) {
    case '1':
      stateStatus = 'Active';
      break;
    case '2':
      stateStatus = 'Inactive/Expired';
      break;
    case '3':
      stateStatus = 'On File';
      break;
    case '4':
      stateStatus = 'On Order';
      break;
    case '5':
      stateStatus = 'Not dispensed';
      break;
    default:
      stateStatus = '';
      break;
  }

  return stateStatus;
}

// Example patient response

// {
//   "objtype": "row",
//   "Id": "10085",
//   "PatientKey": "8900070979",
//   "Pin": "London123$",
//   "Lastname": "Wade",
//   "Firstname": "Wilson",
//   "Birthdate": "3/5/1980",
//   "Address": "501 Belleville St",
//   "City": "Victora",
//   "Province": "BC",
//   "Postal": "",
//   "Country": "Canada",
//   "Phone": "(604) 272-7489",
//   "Email": "",
//   "Status": "Active",
//   "CreatedDate": "4/21/2020 7:40:49 AM",
//   "Confirmation": "20200421074049273",
//   "Storekey": "1",
//   "Sessionmemo": "",
//   "PHN": "",
//   "Agreement": "1",
//   "AgreeDate": "7/22/2020 12:25:13 PM",
//   "PasswordChange": "1",
//   "PasswordDate": "7/22/2020 12:28:18 PM",
//   "LastLoginDate": "",
//   "NumberOfLogins": "0",
//   "Refused": "0",
//   "UserReference": "",
//   "UserType": "",
//   "Tries": "0",
//   "Lockstate": "0",
//   "LastLoginAttempt": "8/4/2020 7:46:20 AM",
//   "AccountLockDate": "",
//   "encryptedpassword": "0",
//   "comment": "",
//   "telepin": "",
//   "contactpreference": "",
//   "otherphone": "",
//   "gender": ""
// }

// Example patient pharmacy response

// {
//   "objtype": "row",
//   "PatientKey": "8900070979",
//   "Status": "Active",
//   "Storekey": "",
//   "ip": "172.16.200.31",
//   "Port": "9002",
//   "Encryptionkey": "",
//   "Compression": "",
//   "Name": "LONDON DRUGS 022-Test 200",
//   "storeAddress": "14951 STONY PLAIN RD NW",
//   "storeCity": "EDMONTON",
//   "storeProvince": "AB",
//   "storePostal": "T5P 4W1",
//   "Country": "Canada",
//   "BannerLink": "",
//   "Order": "",
//   "Counsel": "",
//   "CreatedDate": "4/21/2020 7:40:49 AM",
//   "Userkey": "",
//   "ClinicalIp": "",
//   "ClinicalPort": "",
//   "StoreIdentifier": "LONAB",
//   "BannerImage": "",
//   "Pics": "Y",
//   "Phone": "780-483-8404",
//   "EventDesc": "",
//   "systemtype": "KROLL",
//   "storepatientid": "34",
//   "pin": "",
//   "lastname": "",
//   "firstname": "",
//   "birthdate": "",
//   "address": "",
//   "city": "",
//   "province": "",
//   "postal": "",
//   "userreference": "",
//   "PharmacyUserID": ""
// }
