import { Box, Heading } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import Carousel from 'nuka-carousel';

import LDLink from 'components/common/LDLink';
import { presetComponentProps } from 'theme';
import { ImageBannerData, ImageCMSData } from 'types/cms';
import ProxiedImage from 'components/common/ProxiedImage';

const ImageBannerFullWidth = ({
  bannersCollection,
  headline,
  headlineColor,
  backgroundColor
}: ImageBannerData): ReactElement => {
  const imgCollectionLength = bannersCollection?.items?.length;

  const MappedImage = ({
    image,
    mobileImage,
    index
  }: {
    title: string;
    image: ImageCMSData;
    mobileImage?: ImageCMSData;
    linkUrl: string;
    index: number;
  }) => {
    return (
      <Box mb={imgCollectionLength > 1 && imgCollectionLength - 1 !== index ? '1rem' : 0}>
        {mobileImage && (
          <Box d={{ base: 'block', md: 'none' }}>
            <ProxiedImage
              src={mobileImage?.url}
              alt={mobileImage?.description ?? undefined}
              width={mobileImage?.width}
              height={mobileImage?.height}
              layout="responsive"
            />
          </Box>
        )}
        <Box d={mobileImage ? { base: 'none', md: 'block' } : 'block'}>
          <ProxiedImage
            src={image?.url}
            alt={image?.description ?? undefined}
            width={image?.width}
            height={image?.height}
            layout="responsive"
          />
        </Box>
      </Box>
    );
  };

  const ImageIsLinkSelector = (bannersCollectionItem: {
    title: string;
    image: ImageCMSData;
    mobileImage?: ImageCMSData;
    linkUrl: string;
    index: number;
  }) => (
    <Box key={bannersCollectionItem?.title}>
      {bannersCollectionItem?.linkUrl ? (
        <LDLink
          href={bannersCollectionItem?.linkUrl}
          aria-label={`${bannersCollectionItem?.title} (Banner)`}
        >
          <MappedImage {...{ ...bannersCollectionItem, index: 0 }} />
        </LDLink>
      ) : (
        <MappedImage {...{ ...bannersCollectionItem, index: 0 }} />
      )}
    </Box>
  );

  return (
    <Box w="100%" bgColor={backgroundColor ?? 'transparent'} mb="2.5rem">
      {headline && (
        <Heading
          {...presetComponentProps?.h3}
          color={headlineColor ?? 'primary.default'}
          mb="1.5rem"
          textAlign="center"
        >
          {headline}
        </Heading>
      )}

      {imgCollectionLength >= 2 ? (
        <Carousel
          heightMode="first"
          wrapAround={true}
          initialSlideHeight={280}
          renderBottomCenterControls={({ currentSlide, goToSlide }) => (
            <Box d="flex" flexDir="row" justifyContent="space-evenly">
              {bannersCollection?.items?.map((item, indx) => (
                <Box
                  key={item?.title}
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                  border={currentSlide === indx ? '1px solid' : 'none'}
                  borderColor="primary.default"
                  borderRadius="50"
                  h="16px"
                  w="16px"
                  mx="0.1rem"
                  onClick={() => goToSlide(indx)}
                  _hover={{ cursor: 'pointer' }}
                >
                  <Box
                    borderRadius="50"
                    bgColor={currentSlide === indx ? 'primary.default' : 'shades.250'}
                    h="8px"
                    w="8px"
                  />
                </Box>
              ))}
            </Box>
          )}
        >
          {bannersCollection?.items?.map((item, index) => (
            <React.Fragment key={item?.title}>
              <ImageIsLinkSelector {...{ ...item, index }} />
            </React.Fragment>
          ))}
        </Carousel>
      ) : (
        <ImageIsLinkSelector {...{ ...bannersCollection?.items?.[0], index: 0 }} />
      )}
    </Box>
  );
};

export default ImageBannerFullWidth;
