import { fetchGlobalAppData } from 'api/contentful-fetch';
import { flow, types as t } from 'mobx-state-tree';

const NavItemModel = t.model({
  route: t.maybeNull(t.string),
  title: t.maybeNull(t.string),
  subcategoriesCollection: t.maybeNull(
    t.model({
      items: t.array(
        t.model({
          route: t.maybeNull(t.string),
          title: t.maybeNull(t.string),
          textColor: t.maybeNull(t.string)
        })
      )
    })
  )
});

const NavigationModel = t.array(NavItemModel);

const FooterNavigationModel = t.model({
  complianceNav: t.maybeNull(NavigationModel),
  footerNav: t.maybeNull(NavigationModel)
});

export const CMSStoreModel = t.model({
  mainNavigation: t.maybeNull(NavigationModel),
  footerNavigation: t.maybeNull(FooterNavigationModel),
  globalHeaderBanner: t.maybeNull(
    t.model({
      title: t.maybeNull(t.string),
      bannerText: t.maybeNull(t.string),
      bannerBackgroundColor: t.maybeNull(t.string),
      linkUrl: t.maybeNull(t.string),
      linkText: t.maybeNull(t.string),
      textColor: t.maybeNull(t.string)
    })
  )
});
