import { ReactElement } from 'react';
import NextLink from 'next/link';
import { Box, Button, Image, ButtonProps } from '@chakra-ui/react';

import { LightenDarkenColor } from 'utils';
import { GTMClickEvent } from 'utils/gtmHelpers';
import { useRouter } from 'next/router';

interface EntryData {
  linkUrl?: string;
  bannerText?: string;
  buttonColor: string;
  textColor?: string;
  buttonSize?: string;
  icon?: { url?: string; description?: string };
}

export interface ButtonInPageProps {
  entryData: EntryData;
  desktopAlignmentPosition: 'center' | 'flex-start' | 'flex-end';
}

export const RenderInPageButton = ({
  entryData,
  width
}: {
  entryData: EntryData;
  width?: ButtonProps['width'];
}): JSX.Element => {
  return (
    <Button
      bgColor={entryData?.buttonColor}
      _hover={{ bgColor: LightenDarkenColor(entryData?.buttonColor, -10) }}
      color={entryData?.textColor}
      size={entryData?.buttonSize === 'standard' ? 'md' : 'lg'}
      p="1rem 2rem"
      borderRadius="0"
      fontSize="lg"
      fontWeight={500}
      width={width}
      leftIcon={
        entryData?.icon?.url ? (
          <Image src={entryData?.icon?.url} mr="0.25rem" alt={entryData?.icon?.description} />
        ) : undefined
      }
    >
      {entryData?.bannerText}
    </Button>
  );
};

const ButtonInPage = ({ entryData, desktopAlignmentPosition }: ButtonInPageProps): ReactElement => {
  const { asPath } = useRouter();

  return (
    <NextLink href={entryData?.linkUrl ?? '/'} passHref>
      <Box
        as="a"
        d="flex"
        justifyContent={{ base: 'center', md: desktopAlignmentPosition }}
        w="100%"
        m={0}
        onClick={() => {
          GTMClickEvent(entryData?.bannerText, asPath);
        }}
      >
        <RenderInPageButton entryData={entryData} />
      </Box>
    </NextLink>
  );
};

export default ButtonInPage;
