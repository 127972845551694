import { Button } from '@chakra-ui/button';
import { Box } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import { HeaderOverlayTitlesData, PageCMSData } from 'types/cms';
import Nextlink from 'next/link';
import { Image } from '@chakra-ui/image';
import { commonGradients } from 'theme';
import { urlForwardSlashCleaner } from 'utils';

export interface HeaderOverlayTilesProps {
  data?: HeaderOverlayTitlesData[];
  children?: React.ReactNode;
}

const HeaderOverlayTiles = ({ data, children }: HeaderOverlayTilesProps): ReactElement | null => {
  const type = data?.[0]?.type;
  const tileCollectionItems = data?.[0]?.tileCollection?.items;

  if (!children && !tileCollectionItems) return null;
  interface TileSelectorProps extends PageCMSData {
    index?: number;
  }

  const TileSelector = ({
    parentSlug,
    slug,
    headlineH1,
    iconThumbnail,
    index
  }: TileSelectorProps) => {
    const href = urlForwardSlashCleaner(`${parentSlug}/${slug}`);

    switch (type) {
      case 'icon':
        return (
          <Box
            w={{ base: '100%', sm: '46%', md: '30%' }}
            h={{ base: '75px', sm: '200px' }}
            bgColor="white"
            m={index && index <= 2 ? '0 0' : '1rem 0 0'}
            filter="drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.1))"
          >
            <Nextlink href={href ?? '/'} passHref>
              <Button
                as="a"
                w="100%"
                h="100%"
                bgColor="white"
                _hover={{ bgColor: 'blackAlpha.50' }}
                d="flex"
                flexDir={{ base: 'row', sm: 'column' }}
                fontSize="medium"
                color="primary.medium"
                textAlign={{ base: 'left', sm: 'center' }}
                alignItems="center"
                py={{ base: '1rem', md: '4rem' }}
                px={{ base: '2rem', sm: '4rem' }}
                justifyContent={{ base: 'flex-start', sm: 'center' }}
              >
                {iconThumbnail && (
                  <Image
                    src={iconThumbnail?.url}
                    alt={iconThumbnail.description}
                    mb={{ sm: '0.5rem' }}
                    maxH={{ base: '2.1875rem', sm: '3rem' }}
                    w="auto"
                    mr={{ base: '1rem', sm: '0' }}
                  />
                )}
                <Box as="span" maxW="100%" d="inline-block" whiteSpace="normal">
                  {headlineH1}
                </Box>
              </Button>
            </Nextlink>
          </Box>
        );
      case 'image':
        return (
          <Nextlink href={href ?? '/'} passHref>
            <Button
              as="a"
              w={{ base: '100%', sm: '46%', md: '30%' }}
              h={{ base: '75px', sm: '13.03rem' }}
              bgColor="white"
              m={index && index <= 2 ? '0 0' : '1rem 0 0 0'}
              d="flex"
              flexDir={{ base: 'row', sm: 'column' }}
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              fontSize="medium"
              color="primary.medium"
              textAlign={{ base: 'left', sm: 'center' }}
              alignItems="center"
              p="0"
              justifyContent={{ base: 'flex-start', sm: 'center' }}
              borderRadius={{ sm: 'md' }}
            >
              <Box
                d={{ base: 'none', sm: 'block' }}
                bgImage={iconThumbnail?.url}
                bgPos="center"
                bgRepeat="no-repeat"
                bgSize="cover"
                h="65%"
                w="100%"
                borderTopRadius="md"
              />

              <Box
                as="span"
                h={{ base: '100%', sm: '35%' }}
                w="100%"
                d="flex"
                whiteSpace="normal"
                color="white"
                alignItems="center"
                justifyContent="center"
                p="1rem"
                bg={commonGradients?.primaryDarkToPrimaryDefault}
                borderBottomRadius={{ base: '0', sm: 'md' }}
              >
                {headlineH1}
              </Box>
            </Button>
          </Nextlink>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      d="flex"
      flex="1"
      flexWrap="wrap"
      justifyContent={
        ((children as ReactElement[] | undefined) &&
          ((children as ReactElement[])?.length >= 5 ||
            (children as ReactElement[])?.length === 2)) ||
        (tileCollectionItems &&
          (tileCollectionItems?.length >= 5 || tileCollectionItems?.length >= 2))
          ? 'space-evenly'
          : 'space-between'
      }
      w="100%"
      alignItems="center"
      maxW={{ md: '1024px' }}
    >
      {children ? (
        <>{children}</>
      ) : (
        tileCollectionItems?.map((item, index) => {
          return <TileSelector key={`tile-selector-${index}`} {...item} />;
        })
      )}
    </Box>
  );
};

export default HeaderOverlayTiles;
