import { Box, Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';

import { useStore } from 'store';
import { presetComponentProps } from 'theme';
import {
  MEDICATION_REVIEW_URL,
  PRESCRIPTION_FAQS_URL,
  PRESCRIPTION_PROFILE_URL,
  REFILL_PREFERENCES_URL
} from 'utils/constants';
import { LightenDarkenColor } from 'utils';

const QUICK_LINKS = [
  {
    name: 'My Prescriptions',
    href: PRESCRIPTION_PROFILE_URL
  },
  {
    name: 'Medication Review',
    href: MEDICATION_REVIEW_URL
  },
  {
    name: 'FAQs',
    href: PRESCRIPTION_FAQS_URL
  },
  {
    name: 'Patient Preferences',
    href: REFILL_PREFERENCES_URL
  }
];

const ProfileSummary = (): ReactElement => {
  const { patientStore } = useStore();
  const router = useRouter();
  return (
    <Stack spacing="5">
      <Stack>
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            {...presetComponentProps?.h3}
            mb={0}
            fontWeight="semibold"
            color="primary.default"
          >{`Hi, ${patientStore?.profile?.firstName}`}</Text>
          <Box
            as="button"
            fontSize="sm"
            textDecoration="underline"
            color="primary.default"
            onClick={() => {
              patientStore?.logout();
              router.push(PRESCRIPTION_PROFILE_URL);
            }}
          >
            Sign Out
          </Box>
        </Flex>
        <Divider borderColor="#C6C6C6" />
      </Stack>
      <Stack>
        <Text fontWeight="semibold" color="primary.default">
          Quick Links
        </Text>
        <Flex flexWrap="wrap" gridGap="2">
          {QUICK_LINKS.map(({ name, href }, index) => (
            <NextLink href={href} passHref key={`quick-link-${index}`}>
              <Button
                width="fit-content"
                as="a"
                paddingX="2"
                backgroundColor="#E2E8F0"
                _hover={{ backgroundColor: LightenDarkenColor('#E2E8F0', -10) }}
              >
                {name}
              </Button>
            </NextLink>
          ))}
        </Flex>
      </Stack>
      <Divider borderColor="#C6C6C6" />
      <NextLink href={PRESCRIPTION_PROFILE_URL} passHref>
        <Button variant="ld" width="fit-content" fontWeight="semibold">
          Go to My Profile
        </Button>
      </NextLink>
    </Stack>
  );
};

export default ProfileSummary;
