import { Image } from '@chakra-ui/image';
import { Box, Heading, Stack } from '@chakra-ui/layout';
import NextLink from 'next/link';
import React, { ReactElement } from 'react';

import { PageBodyText } from 'components/CMS';
import LDThemeButton from 'components/common/LDThemeButton';
import { presetComponentProps } from 'theme';
import { PageBodyInsetBlockData } from 'types/cms';

export interface PageBodyInsetBlockProps extends PageBodyInsetBlockData {}

const PageBodyInsetBlock = ({
  image,
  backgroundColor,
  text,
  replaceBulletsWithArrows,
  headline,
  headlineColor,
  __typename,
  buttonText,
  buttonColor,
  buttonLink,
  title
}: PageBodyInsetBlockProps): ReactElement => {
  return (
    <Stack
      spacing={{ base: 3, md: 4 }}
      pos="relative"
      w={{ base: '100vw', md: '100%' }}
      bgColor={backgroundColor}
      p={{ base: '1.75rem', md: '2.8125rem' }}
      d="flex"
      justifyContent="center"
      flexDir="column"
      borderRadius={{ md: '1.25rem' }}
      ml={{
        base: `-${presetComponentProps?.pageContainer?.paddingX?.base} !important`,
        md: '0 !important'
      }}
      mb="1.5rem !important"
    >
      {image?.url && !headline && (
        <Box d="flex" justifyContent="center">
          <Image src={image?.url} alt={image?.description} maxW="6rem" h="auto" />
        </Box>
      )}
      {!image?.url && headline && (
        <Heading
          {...presetComponentProps?.h3}
          fontSize={{ base: 'xl', lg: '2xl' }}
          color={headlineColor}
          textAlign="center"
          mx="auto"
          maxW="350px"
          mb="1rem"
        >
          {headline}
        </Heading>
      )}
      {text && (
        <PageBodyText
          data={{ title: title + ' Body Text', pageBodyText: text, __typename }}
          replaceBulletsWithArrows={replaceBulletsWithArrows}
          usedForInsetBlockOnly
        />
      )}
      {buttonLink && (
        <Box d="flex" justifyContent="center">
          <NextLink href={buttonLink ?? '/'} passHref>
            <LDThemeButton
              as="a"
              bgColor={buttonColor}
              w="auto"
              mx="auto"
              fontSize={{ md: '1.125rem' }}
              fontWeight={700}
              minH="48px"
              px="1.125rem"
            >
              {buttonText}
            </LDThemeButton>
          </NextLink>
        </Box>
      )}
    </Stack>
  );
};

export default PageBodyInsetBlock;
