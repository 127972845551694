import React, { ReactElement, ReactNode } from 'react';
import { Box, Flex, Stack, Text, Container } from '@chakra-ui/react';
import { Image } from '@chakra-ui/image';
import NextLink from 'next/link';
import { observer } from 'mobx-react';
import Carousel from 'nuka-carousel';

import { commonGradients } from 'theme';
import { PageCMSData } from 'types/cms';
import HeroWave from '../../public/assets/hero-wave.svg';
import ProfileSummary from 'components/ProfileSummary';
import { useStore } from 'store';
import LoginForm from 'components/forms/LoginForm';
import LDThemeButton from 'components/common/LDThemeButton';

export interface HomeHeroProps {
  data: PageCMSData;
}
export interface HomeHeroCarouselProps extends HomeHeroProps {
  children: ReactNode;
  className?: string;
}

const HomeHeroCarousel = ({ className, data, children }: HomeHeroCarouselProps) => {
  return (
    <Carousel
      className={className}
      autoplay={true}
      heightMode="first"
      wrapAround={true}
      transitionMode="fade"
      initialSlideHeight={280}
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null}
      renderBottomCenterControls={({ currentSlide, goToSlide }) => (
        <Box
          d="flex"
          flexDir="row"
          justifyContent="space-evenly"
          mt="-4rem"
          width="fit-content"
          bottom={0}
        >
          {data?.homeHeroCollection?.items?.map((item, indx) => (
            <Box
              aria-label={`Hero image carousel nav button ${indx + 1}${
                indx === indx ? ': current' : ''
              }`}
              key={item?.title + ' mobile-control'}
              as="button"
              onClick={() => {
                goToSlide(indx);
              }}
              _hover={{ cursor: 'pointer' }}
              width="48px"
              height="48px"
              d="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                d="flex"
                alignItems="center"
                justifyContent="center"
                border={currentSlide === indx ? '1px solid' : 'none'}
                borderColor="primary.default"
                borderRadius="50"
                h="20px"
                w="20px"
                mx="0.1rem"
              >
                <Box
                  borderRadius="50"
                  bgColor={currentSlide === indx ? 'primary.default' : 'white'}
                  h="12px"
                  w="12px"
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    >
      {children}
    </Carousel>
  );
};

const HomeHero = ({ data }: HomeHeroProps): ReactElement => {
  const { patientStore } = useStore();

  const heroOverlayContent = data?.heroOverlaySubFeaturesCollection?.items;
  const hasOverlayContent = heroOverlayContent && heroOverlayContent?.length > 0;

  return (
    <>
      {/* mobile */}
      <Box marginBottom="5" display={{ base: 'block', xl: 'none' }}>
        <HomeHeroCarousel data={data}>
          {data?.homeHeroCollection?.items?.map((item) => (
            <NextLink key={item?.title + ' mobile'} href={item?.bannerLink ?? '/404'} passHref>
              <Box
                as="a"
                position="relative"
                bgColor={item?.backgroundColor}
                d="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  d={{ base: 'block', sm: 'none' }}
                  src={item?.mobileImage?.url ?? item?.image?.url}
                  width="100%"
                  height="auto"
                  alt={item?.mobileImage?.description ?? item?.image?.description}
                />
                <Image
                  d={{ base: 'none', sm: 'block' }}
                  src={item?.image?.url}
                  width="100%"
                  height="auto"
                  alt={item?.image?.description}
                />
              </Box>
            </NextLink>
          ))}
        </HomeHeroCarousel>
        {hasOverlayContent && (
          <Stack padding="4" spacing="3" marginTop="5">
            {heroOverlayContent?.map(({ icon, linkUrl, text }, index) => (
              <NextLink key={`home-hero-overlay-${index}`} href={linkUrl} passHref>
                <Flex
                  as="a"
                  justifyContent="center"
                  alignItems="center"
                  bg={commonGradients?.primaryDarkToPrimaryDefault}
                  padding="5"
                  borderRadius="20px"
                >
                  {icon && (
                    <Image src={icon.url} height="40px" width="40px" marginRight="1" alt={text} />
                  )}
                  <Text color="white" fontSize="lg" fontWeight="bold">
                    {text}
                  </Text>
                </Flex>
              </NextLink>
            ))}
          </Stack>
        )}
      </Box>
      {/* Desktop */}
      <Box
        position="relative"
        h="700px"
        w="100%"
        mb="100px"
        background={commonGradients?.transparentToWhiteGray}
        display={{ base: 'none', xl: 'block' }}
      >
        <Box
          bgColor={data?.homeHeroCollection?.items?.[0]?.backgroundColor}
          height="500px"
          w="100%"
          mx="auto"
          d="flex"
          justifyContent="center"
        >
          <Box w="100%" maxW="container.xxl">
            <Box
              background={commonGradients?.transparentToWhiteGray}
              mb={{ md: '2.2em', xl: '0.875em', '2xl': '0' }}
            >
              <Flex h="auto" d="flex" justifyContent="center">
                <Flex
                  flex={2}
                  justifyContent="center"
                  alignItems="center"
                  pos="relative"
                  height="500px"
                >
                  <HomeHeroCarousel className="desktop-home-hero-carousel" data={data}>
                    {data?.homeHeroCollection?.items?.map((item) => (
                      <NextLink
                        key={item?.title + ' desktop'}
                        href={item?.bannerLink ?? '/404'}
                        passHref
                      >
                        <Box
                          key={item?.title + ' mobile'}
                          as="a"
                          position="relative"
                          bgColor={item?.backgroundColor}
                          d="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            d={{ base: 'none', sm: 'block' }}
                            src={item?.image?.url}
                            width="100%"
                            height="auto"
                            alt={item?.image?.description}
                          />
                        </Box>
                      </NextLink>
                    ))}
                  </HomeHeroCarousel>
                </Flex>
                <Box
                  flex={1}
                  display={{ md: 'block' }}
                  width={{ md: '40%', lg: '100%' }}
                  sx={{
                    '@media (min-width:1280px) and (max-width:1443px)': {
                      d: 'flex',
                      justifyContent: 'flex-end',
                      width: '25%',
                      flex: 'unset'
                    },
                    '@media (min-width:1342px) and (max-width:1348px)': {
                      width: '28%'
                    },
                    '@media (min-width:1349px) and (max-width:1427px)': {
                      width: '28%'
                    },
                    '@media (min-width:1427px) and (max-width:1443px)': {
                      width: '30%'
                    }
                  }}
                >
                  <Stack
                    spacing="2"
                    height="100%"
                    width={'fit-content'}
                    flex="1"
                    backgroundColor="white"
                    padding={{ base: '0', md: '4', lg: '8' }}
                    sx={{
                      '@media (min-width:1280px) and (max-width:1443px)': {
                        width: '400px !important',
                        py: 6,
                        flex: 'unset'
                      },
                      '@media (min-width:1331px) and (max-width:1443px)': {
                        width: '100%',
                        flex: 1
                      }
                    }}
                  >
                    {!patientStore?.isLoggedIn ? (
                      <>
                        <Text fontSize="2xl" fontWeight="bold" color="primary.default">
                          Access your account
                        </Text>
                        <LoginForm />
                      </>
                    ) : (
                      <ProfileSummary />
                    )}
                  </Stack>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
        <Box
          position="absolute"
          bottom="0"
          h="auto"
          w="100%"
          d="flex"
          flexDir="column"
          justifyContent="center"
          color="black"
          zIndex={9}
          display={{ base: 'none', md: 'block' }}
        >
          <Box as={HeroWave} fill="white" h="auto" w="100%" display="block" my={0} />

          <Box
            h={hasOverlayContent ? 'auto' : '2em'}
            w="100%"
            pt={{ base: '1em', md: 0 }}
            position="absolute"
            mt="-10rem"
          >
            {hasOverlayContent && (
              <Container maxW="container.xxl">
                <Stack
                  direction="row"
                  spacing="4"
                  h="auto"
                  bgColor="transparent"
                  width="100%"
                  d="flex"
                  justifyContent="center"
                >
                  {heroOverlayContent?.map(({ icon, linkUrl, text }, index) => (
                    <NextLink key={`home-hero-overlay-${index}`} href={linkUrl} passHref>
                      <LDThemeButton
                        as="a"
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        bg={commonGradients?.primaryDarkToPrimaryDefault}
                        padding={{ md: '40px', lg: '50px', xl: '60px' }}
                        borderRadius="20px"
                        minWidth={{ md: '200px', lg: '300px', xl: '400px' }}
                        height="150px"
                      >
                        {icon && (
                          <Image
                            src={icon.url}
                            height="60px"
                            width="60px"
                            marginRight={6}
                            alt={text + ' Icon'}
                          />
                        )}
                        <Text color="white" fontSize="2xl" fontWeight="bold">
                          {text}
                        </Text>
                      </LDThemeButton>
                    </NextLink>
                  ))}
                </Stack>
              </Container>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default observer(HomeHero);
