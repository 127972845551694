import { Button } from '@chakra-ui/button';
import { useTheme } from '@chakra-ui/system';
import React, { ComponentProps, forwardRef, LegacyRef } from 'react';
import { LightenDarkenColor } from 'utils';

export interface LDThemeButtonProps
  extends Omit<ComponentProps<typeof Button>, 'textColor' | 'backgroundColor'> {}

const LDThemeButton = forwardRef(
  (
    { children, bgColor, fontWeight, ...rest }: LDThemeButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    const theme = useTheme();
    const innerBgColor: string = bgColor ?? theme.colors.primary.default;

    const autoTextColor = () => {
      if (bgColor === theme.colors.primary.default) return '#fff';

      if (bgColor === theme.colors.primary.dark) return '#fff';

      if (bgColor === theme.colors.shades[50]) return theme.colors.primary.dark;

      if (bgColor === '#fff' || bgColor === '#ffffff') return theme.colors.primary.dark;

      return '#fff';
    };

    return (
      <Button
        {...rest}
        ref={ref}
        bgColor={innerBgColor}
        fontWeight={fontWeight ?? 500}
        textColor={autoTextColor()}
        _hover={{
          bgColor: LightenDarkenColor(innerBgColor, -10)
        }}
        minH="48px"
      >
        {children}
      </Button>
    );
  }
);

export default LDThemeButton;
