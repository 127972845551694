import { ReactElement } from 'react';
import { observer } from 'mobx-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  Box,
  Container,
  Flex,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  Tooltip,
  Image
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import Banner from '../CMS/Banner';
import DesktopNavigation from './DesktopNavigation';
import photoLabLogo from '../../public/assets/photo-lab-icon.png';
import { useStore } from 'store';
import {
  MEDICATION_REVIEW_URL,
  PRESCRIPTION_FAQS_URL,
  PRESCRIPTION_PROFILE_URL,
  REFILL_PREFERENCES_URL
} from 'utils/constants';
import ProxiedImage from 'components/common/ProxiedImage';
import SearchBar from 'components/algolia/SearchBar';
import { GTMClickEvent } from 'utils/gtmHelpers';

const Header = (): ReactElement | null => {
  const { cmsStore, patientStore } = useStore();
  const router = useRouter();
  const { globalHeaderBanner, mainNavigation } = cmsStore;

  return (
    <Box display={{ base: 'none', md: 'block' }}>
      {globalHeaderBanner && <Banner {...globalHeaderBanner} isGlobalHeaderBanner />}
      <Container maxW="container.xxl">
        <Flex justifyContent="space-between" paddingY="1">
          <Flex fontSize={{ base: 'sm', lg: 'md' }}>
            <Box paddingX="4" paddingY="2.5" borderLeft="1px" borderColor="shades.50">
              <NextLink href="https://www.londondrugs.com/" passHref>
                <a onClick={() => GTMClickEvent('Header - Shop', 'global')}>
                  <Text color="primary.dark" fontWeight="medium">
                    Shop
                  </Text>
                </a>
              </NextLink>
            </Box>
            <Box paddingX="4" paddingY="2.5" backgroundColor="shades.50">
              <NextLink href="/" passHref>
                <a>
                  <Text color="primary.dark" fontWeight="medium">
                    Pharmacy
                  </Text>
                </a>
              </NextLink>
            </Box>
            <Box paddingX="4" paddingY="2.5" borderLeft="1px" borderColor="shades.50">
              <NextLink href="https://photolab.londondrugs.com/" passHref>
                <a
                  onClick={() => {
                    GTMClickEvent('Header - Photolab', 'global');
                  }}
                >
                  <Flex alignItems="center">
                    <ProxiedImage
                      src={photoLabLogo}
                      width={16}
                      height={17}
                      layout="fixed"
                      alt="PhotoLab Logo"
                    />
                    <Text marginLeft="1" color="primary.dark" fontWeight="medium">
                      photolab
                    </Text>
                  </Flex>
                </a>
              </NextLink>
            </Box>
            <Box
              paddingX="4"
              paddingY="2.5"
              borderLeft="1px"
              borderRight="1px"
              borderColor="shades.50"
            >
              <a href="https://www.ldextras.com/" target="_blank" rel="noreferrer">
                <Text color="primary.dark" fontWeight="bold">
                  LD
                  <Text as="span" fontStyle="italic" color="accent.ldExtras" fontWeight="medium">
                    Extras
                  </Text>
                </Text>
              </a>
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Box
              as={Link}
              paddingRight="2"
              borderRight="1px"
              borderRightColor="shades.200"
              href="tel:18889912299"
            >
              <Text fontSize="sm" color="primary.dark">
                Customer Service: 1-888-991-2299
              </Text>
            </Box>
            <Box
              as={Link}
              href="https://www.londondrugs.com/store-flyers/eflyer.html"
              target="_blank"
              rel="noreferrer"
              paddingX="2"
              borderRight="1px"
              borderRightColor="shades.200"
              onClick={() => {
                GTMClickEvent('Header - Flyers', 'global');
              }}
            >
              <Text fontSize="sm" color="primary.dark">
                Flyers
              </Text>
            </Box>
            <Box
              as={Link}
              paddingLeft="2"
              href="https://www.londondrugs.com/locations/"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                GTMClickEvent('Header - Locations', 'global');
              }}
            >
              <Text fontSize="sm" color="primary.dark">
                Locations
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Container>
      <Box backgroundColor="primary.default" paddingY="4">
        <Container maxW="container.xxl">
          <Flex justifyContent="space-between" alignItems="center">
            <Box flex={1}>
              <NextLink href="/" passHref>
                <a>
                  <Image
                    src="/assets/pharmacy-logo.svg"
                    width={419}
                    height={91}
                    alt="London Drugs logo"
                  />
                </a>
              </NextLink>
            </Box>
            <Flex flex={1} justifyContent="flex-end">
              <Box flex={2}>
                <SearchBar />
              </Box>
              <Flex marginLeft="5" flex={1} alignItems="center">
                {patientStore?.isLoggedIn ? (
                  <Flex>
                    <Box
                      marginRight="3"
                      as="button"
                      fontSize="sm"
                      color="white"
                      onClick={() => {
                        patientStore?.logout();
                        router.push(PRESCRIPTION_PROFILE_URL);
                      }}
                    >
                      Sign Out
                    </Box>
                    <Popover placement="bottom-end" trigger="hover" isLazy id="account-popover">
                      {() => (
                        <>
                          <PopoverTrigger>
                            <Flex alignItems="center">
                              <Text fontSize="sm" color="white">
                                My Account
                              </Text>
                              <ChevronDownIcon color="white" />
                            </Flex>
                          </PopoverTrigger>
                          <PopoverContent width={250}>
                            <PopoverBody padding="0">
                              <NextLink href={PRESCRIPTION_PROFILE_URL} passHref>
                                <Link
                                  display="block"
                                  paddingY="3"
                                  paddingLeft="3"
                                  _hover={{ backgroundColor: 'shades.50' }}
                                >
                                  My Prescriptions
                                </Link>
                              </NextLink>
                              <NextLink href={MEDICATION_REVIEW_URL} passHref>
                                <Link
                                  display="block"
                                  paddingY="3"
                                  paddingLeft="3"
                                  _hover={{ backgroundColor: 'shades.50' }}
                                >
                                  Medication Review
                                </Link>
                              </NextLink>
                              <NextLink href={PRESCRIPTION_FAQS_URL} passHref>
                                <Link
                                  display="block"
                                  paddingY="3"
                                  paddingLeft="3"
                                  _hover={{ backgroundColor: 'shades.50' }}
                                >
                                  FAQs
                                </Link>
                              </NextLink>
                              <NextLink href={REFILL_PREFERENCES_URL} passHref>
                                <Link
                                  display="block"
                                  paddingY="3"
                                  paddingLeft="3"
                                  _hover={{ backgroundColor: 'shades.50' }}
                                >
                                  Patient Preferences
                                </Link>
                              </NextLink>
                            </PopoverBody>
                          </PopoverContent>
                        </>
                      )}
                    </Popover>
                  </Flex>
                ) : (
                  <Text fontSize="sm" color="white">
                    <NextLink href={PRESCRIPTION_PROFILE_URL} passHref>
                      <a
                        onClick={() => {
                          GTMClickEvent('Header - Sign In', 'global');
                        }}
                      >
                        Sign In
                      </a>
                    </NextLink>
                    <Text as="span" color="shades.300">
                      {' '}
                      or{' '}
                    </Text>
                    <Tooltip
                      padding="3"
                      label="If you have a temporary password, please use the sign in page. If you are new and want to create an account, please contact your local London Drugs Pharmacy."
                    >
                      Create an Account
                    </Tooltip>
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
      {mainNavigation && <DesktopNavigation navItems={mainNavigation} />}
    </Box>
  );
};

export default observer(Header);
