import { encodeQueryParams } from './util/query-utils';

// const url = 'https://www.londondrugs.com/dw/shop/v18_8'
const url = 'https://www.londondrugs.com/dw/shop/v18_8';
const clientId = 'b119c173-07f8-4da0-9600-4eea285a8814';

export const searchStores = async (data?: any) => {
  const requestData = data || {
    latitude: '49.121726',
    longitude: '-123.1010515',
    count: 200
  };

  const fetchUrl = `${url}/stores?client_id=${clientId}&${encodeQueryParams(requestData)}`;

  const stores = await fetch(fetchUrl)
    .then((r) => r.json())
    .catch((err) => console.error(err));

  if (!stores?.count || stores?.count === 0) {
    throw new Error('NO_STORES_FOUND');
  }

  return stores.data.map((store: any) => ({
    ...store,
    pharmacyPhone: extractPharmacyPhone(store)
  }));
};

function extractPharmacyPhone(store: any) {
  try {
    const serviceshtml = store.c_serviceshtml.split(',');
    const phone = serviceshtml[serviceshtml.findIndex((i: string) => i === 'Pharmacy') + 1];
    if (!phone || phone === 'none') {
      return undefined;
    }
    return phone;
  } catch (error) {
    return undefined;
  }
}

export const getStoreById = async (storeId: string) => {
  const fetchUrl = `${url}/stores/${storeId}?client_id=${clientId}`;
  const store = await fetch(fetchUrl).then((r) => r.json());
  return store;
};
