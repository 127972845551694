import { Box, Divider, Stack, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export interface BlockQuoteProps {
  children: React.ReactNode;
  textColor?: string;
}

const BlockQuote = ({ children, textColor }: BlockQuoteProps): ReactElement => {
  return (
    <Stack spacing={{ base: '4', md: '6' }} mt="1.5rem !important">
      <Box width="100%" maxWidth={{ base: 'unset', md: '374px' }} margin="0 auto">
        <Divider borderColor="#C6C6C6" />
      </Box>
      <Text
        textAlign="center"
        fontSize={{ base: 'lg', md: 'xl' }}
        lineHeight={{ base: '10', md: '44px' }}
        fontStyle="italic"
        color={textColor ? textColor : 'black'}
      >
        {children}
      </Text>
      <Box
        width="100%"
        maxWidth={{ base: 'unset', md: '374px' }}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Divider borderColor="#C6C6C6" />
      </Box>
    </Stack>
  );
};

export default BlockQuote;
