import { types as t, flow } from 'mobx-state-tree';

export type ModalOptions = 'signIn' | 'pharmacyProfile' | 'updatePassword';

export interface ModalControls {
  open: (modal: ModalOptions) => void;
  close: (modal: ModalOptions) => void;
  isOpen: boolean;
  toggle: (modal: ModalOptions) => void;
}

export interface UpdatePasswordData {
  id: string;
  pin: string;
}

export const ModalsAndDrawersModel = t
  .model({
    pharmacyProfile: t.boolean,
    signIn: t.model('signIn', {
      isOpen: t.boolean,
      nextRoute: t.maybe(t.string),
      openProfileOnSignIn: t.boolean
    }),
    updatePassword: t.model('updatePassword', {
      isOpen: t.boolean,
      id: t.maybe(t.string),
      pin: t.maybe(t.string)
    })
  })
  .actions((self: any) => {
    return {
      toggle: (settingName: ModalOptions) => {
        if (settingName === 'signIn') {
          self.signIn.isOpen = !self.signIn.isOpen;
        } else if (settingName === 'updatePassword') {
          self.updatePassword.isOpen = !self.updatePassword.isOpen;
        } else {
          self[settingName] = !self[settingName];
        }
      },
      open: (settingName: ModalOptions) => {
        if (settingName === 'signIn') {
          self.signIn.isOpen = true;
        } else if (settingName === 'updatePassword') {
          self.updatePassword.isOpen = true;
        } else {
          self[settingName] = true;
        }
      },
      close: (settingName: ModalOptions) => {
        if (settingName === 'signIn') {
          self.signIn.isOpen = false;
        } else if (settingName === 'updatePassword') {
          self.updatePassword.isOpen = false;
        } else {
          self[settingName] = false;
        }
      },
      signInRoute: (nextRoute: string) => {
        self.signIn.nextRoute = nextRoute;
      },

      openProfileOnSignInToggle: () => {
        self.signIn.openProfileOnSignIn = !self.signIn.openProfileOnSignIn;
      },
      updatePasswordData: (data: UpdatePasswordData) => {
        self.updatePassword.id = data?.id;
        self.updatePassword.pin = data?.pin;
      }
    };
  });
