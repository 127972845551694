import { applySnapshot, getSnapshot, flow, types as t } from 'mobx-state-tree';
import { clear } from '../api/util/storage-utils';
import { LocationStoreModel } from './location.model';
import { MiscStoreModel } from './misc.model';
import { ModalsAndDrawersModel } from './modalsAndDrawers.model';
import { PatientStoreModel } from './patient.model';
import { SettingsStoreModel } from './settings.model';
import { CMSStoreModel } from './cms.model';

export const RootStore = t
  .model({
    cmsStore: t.optional(CMSStoreModel, {}),
    patientStore: t.optional(PatientStoreModel, {}),
    locationStore: t.optional(LocationStoreModel, {}),
    miscStore: t.optional(MiscStoreModel, {}),
    settingsStore: t.optional(SettingsStoreModel, {}),
    modalsAndDrawersStore: t.optional(ModalsAndDrawersModel, {
      pharmacyProfile: false,
      signIn: {
        isOpen: false,
        nextRoute: undefined,
        openProfileOnSignIn: false
      },
      updatePassword: {
        isOpen: false,
        id: undefined,
        pin: undefined
      }
    })
  })
  .actions((self) => {
    let initialState = {};

    const afterCreate = () => {
      initialState = getSnapshot(self);
    };

    const reset = () => {
      applySnapshot(self, initialState);
    };

    return {
      afterCreate: () => {
        afterCreate();
        self.locationStore.fetchAllLocations();
      },

      clearAllData: () => {
        return clear(undefined).then(() => reset());
      }
    };
  });
