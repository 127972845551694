import { ComponentProps, ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';

export interface ApiErrorProps extends ComponentProps<typeof Box> {
  message?: string;
}

const ApiError = ({
  message = 'Something went wrong. Please try again.',
  ...props
}: ApiErrorProps): ReactElement => {
  return (
    <Box
      backgroundColor="red.100"
      padding={props?.padding ?? '3'}
      marginBottom={props?.marginBottom ?? '4'}
      {...props}
    >
      <Text color="status.alert">{message}</Text>
    </Box>
  );
};

export default ApiError;
