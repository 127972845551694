import { CONTENT_TYPES } from 'utils/constants';
import {
  BANNER_IN_PAGE,
  BUTTON_IN_PAGE,
  COLUMNS_IN_PAGE,
  FEATURE_SPLIT,
  IMAGE_GRID_IN_PAGE,
  VIDEO_WITH_THUMBNAIL
} from '.';
import { BLOCK_QUOTE, FEATURE_BANNER, IMAGE_BANNER, LOCATION_BANNER, TEXT_BANNER } from './global';

export const IMAGE_BANNER_FULL_WIDTH = `
  fragment ${CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER}Fragment on ${CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER} {
      title
      headline
      headlineColor
      backgroundColor
      bannersCollection {
        items {
          title
          linkUrl
          image {
            ...Image
          }
          mobileImage {
            ...Image
          }
        }
      }
    }
`;

export const IMAGE_BANNER_THREE_UP = `
fragment ${CONTENT_TYPES.IMAGE_BANNER_THREE_UP}Fragment on ${CONTENT_TYPES.IMAGE_BANNER_THREE_UP} {
    title
    headline
    headlineColor
    backgroundColor
    bannersCollection {
      items {
        title
        linkUrl
        image {
          ...Image
        }
        mobileImage {
          ...Image
        }
      }
    }
  }
`;

export const PAGE_BODY_INSET_BLOCK = `
fragment PageBodyInsetBlockFragment on ${CONTENT_TYPES.PAGE_BODY_INSET_BLOCK} {
  title
  image {
    ...Image
  }
  headline
  headlineColor
  buttonText
  buttonLink
  buttonColor
  backgroundColor
  replaceBulletsWithArrows
  text {
    json
  }
  sys {
    id
  }
}
`;

export const PAGE_SUMMARY_BLOCK = `
fragment ${CONTENT_TYPES.PAGE_SUMMERY_BLOCK}Fragment on ${CONTENT_TYPES.PAGE_SUMMERY_BLOCK} {
  headline
  headlineColor
  buttonColor
  buttonText
  blocksCollection {
    items {
      parentSlug
      slug
      headlineH1
      shortDescription
      iconThumbnail {
        ...Image
      }
    }
  }
}
`;

export const FAQ_ITEM = `
fragment FaqItemFragment on ${CONTENT_TYPES.FAQ_ITEM} {
    question
    answer
}
`;

export const HEALTH_PRODUCT = `
fragment HealthProductFragment on ${CONTENT_TYPES.HEALTH_PRODUCT} {
    title
    linkUrl
    image {
        ...Image
    }
}
`;

export const PHARMACIST = `
fragment PharmacistFragment on ${CONTENT_TYPES.PHARMACIST} {
    name
    jobTitle
    pharmacyTitle
    certifications
    bio {
        json
    }
    email
    phone
    languages
    location
    image {
        ...Image
    }
}
`;

export const TEXT_BANNER_WITH_MODAL = `
fragment TextBannerWithModalFragment on TextBannerWithModal {
  headline
  headlineColor
  text
  image {
    ...Image
  }
  buttonText
  buttonColor
  modalText {
    json
  }
}
`;

export const PAGE_BODY_TEXT = `
${IMAGE_GRID_IN_PAGE}
${COLUMNS_IN_PAGE}
${VIDEO_WITH_THUMBNAIL}
${BUTTON_IN_PAGE}
${IMAGE_BANNER}
${BLOCK_QUOTE}
${PAGE_BODY_INSET_BLOCK}
fragment PageBodyTextFragment on ${CONTENT_TYPES.PAGE_BODY_TEXT} {
  __typename
  title
  pageBodyText: text {
    json
    links  {
      assets {
        block {
          ...on ${CONTENT_TYPES.ASSET} {
            title
            description
            url
            sys {
              id
            }
          }
        }
      }
      entries {
        block {
          ...on ${CONTENT_TYPES.COLUMNS_IN_PAGE} {
            ...ColumnsInPageFragment
          }
          ...on ${CONTENT_TYPES.BANNER_IN_PAGE} {
            ...BannerInPageFragment
          }
          ...on ${CONTENT_TYPES.BLOCK_QUOTE} {
            ...BlockQuoteFragment
          }
          ...on ${CONTENT_TYPES.BUTTON_IN_PAGE} {
            ...ButtonInPageFragment
          }
          ...on ${CONTENT_TYPES.VIDEO_WITH_THUMBNAIL} {
            ...${CONTENT_TYPES.VIDEO_WITH_THUMBNAIL}Fragment
          }
          ...on ${CONTENT_TYPES.FEATURE_BANNER} {
            ...FeatureBannerFragment
          }
          ...on ${CONTENT_TYPES.IMAGE_BANNER} {
            ...ImageBannerFragment
          }
          ...on ${CONTENT_TYPES.IMAGE_GRID_IN_PAGE} {
            ...ImageGridInPageFragment
          }
          ...on ${CONTENT_TYPES.PAGE_BODY_INSET_BLOCK} {
            ...PageBodyInsetBlockFragment
          }
          
          __typename
        }
      }
    } 
  }
}
`;

export const BODY_COLLECTION = `
${FAQ_ITEM}
${FEATURE_SPLIT}
${PAGE_BODY_TEXT}
${BANNER_IN_PAGE}
${FEATURE_BANNER}
${PHARMACIST}
${HEALTH_PRODUCT}
${TEXT_BANNER_WITH_MODAL}
${IMAGE_BANNER_FULL_WIDTH}
${LOCATION_BANNER}
${IMAGE_BANNER_THREE_UP}
${TEXT_BANNER}
${PAGE_SUMMARY_BLOCK}
fragment bodyCollectionFragment on ${CONTENT_TYPES.PAGE_BODY_COLLECTION} {
  items {
    __typename
    ...on ${CONTENT_TYPES.SIDE_BY_SIDE_BANNERS} {
      title
      headline
      headlineColor
      bannersCollection(limit: 9) {
        items {
          __typename
          ...on ${CONTENT_TYPES.PHARMACIST} {
            ...PharmacistFragment
          }
          ...on ${CONTENT_TYPES.TEXT_BANNER_WITH_MODAL} {
            ...TextBannerWithModalFragment
          }
          ...on ${CONTENT_TYPES.LOCATION_BANNER} {
            ...LocationBannerFragment
          }
          ...on ${CONTENT_TYPES.TEXT_BANNER} {
            ...TextBannerFragment
          }
          
        }
      }
    }
    ...on ${CONTENT_TYPES.LIST} {
      title
      headline
      headlineColor
      text
      itemCollection {
        items {
          __typename
          ...on ${CONTENT_TYPES.FAQ_ITEM} {
            ...FaqItemFragment
          }
          ...on ${CONTENT_TYPES.PHARMACIST} {
            ...PharmacistFragment
          }
          ...on ${CONTENT_TYPES.HEALTH_PRODUCT} {
            ...HealthProductFragment
          }
          ...on ${CONTENT_TYPES.TEXT_BANNER_WITH_MODAL} {
            ...TextBannerWithModalFragment
          }
        }
      }
    }
    ...on ${CONTENT_TYPES.PAGE_BODY_TEXT} {
      ...PageBodyTextFragment
    }
    ...on ${CONTENT_TYPES.FEATURE_SPLIT} {
      ...FeatureSplitFragment
    }
    ...on ${CONTENT_TYPES.BANNER_IN_PAGE} {
      ...BannerInPageFragment
    }
    ...on ${CONTENT_TYPES.FEATURE_BANNER} {
        ...FeatureBannerFragment
    }
    ... on ${CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER} {
      ...${CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER}Fragment
    }
    ... on ${CONTENT_TYPES.IMAGE_BANNER_THREE_UP} {
      ...${CONTENT_TYPES.IMAGE_BANNER_THREE_UP}Fragment
    }
    ...on ${CONTENT_TYPES.PAGE_SUMMERY_BLOCK} {
      ...${CONTENT_TYPES.PAGE_SUMMERY_BLOCK}Fragment
    }
  }  
}
`;

export const CAT_BODY_COLLECTION = `
${PAGE_BODY_TEXT}
${FEATURE_SPLIT}
${FAQ_ITEM}
${BANNER_IN_PAGE}
${FEATURE_BANNER}
${PHARMACIST}
${HEALTH_PRODUCT}
${TEXT_BANNER_WITH_MODAL}
${IMAGE_BANNER_FULL_WIDTH}
${LOCATION_BANNER}
${IMAGE_BANNER_THREE_UP}
${TEXT_BANNER}
${PAGE_SUMMARY_BLOCK}
fragment categoryBodyCollectionFragment on ${CONTENT_TYPES.CATEGORY_PAGE_BODY_COLLECTION} {
items {
__typename
...on ${CONTENT_TYPES.SIDE_BY_SIDE_BANNERS} {
  title
  headline
  headlineColor
  bannersCollection(limit: 9) {
    items {
      __typename
      ...on ${CONTENT_TYPES.PHARMACIST} {
        ...PharmacistFragment
      }
      ...on ${CONTENT_TYPES.TEXT_BANNER_WITH_MODAL} {
        ...TextBannerWithModalFragment
      }
      ...on ${CONTENT_TYPES.LOCATION_BANNER} {
        ...LocationBannerFragment
      }
      ...on ${CONTENT_TYPES.TEXT_BANNER} {
        ...TextBannerFragment
      }
    }
  }
}
...on ${CONTENT_TYPES.LIST} {
  title
  headline
  headlineColor
  text
  itemCollection {
    items {
      __typename
      ...on ${CONTENT_TYPES.FAQ_ITEM} {
        ...FaqItemFragment
      }
      ...on ${CONTENT_TYPES.PHARMACIST} {
        ...PharmacistFragment
      }
      ...on ${CONTENT_TYPES.HEALTH_PRODUCT} {
        ...HealthProductFragment
      }
      ...on ${CONTENT_TYPES.TEXT_BANNER_WITH_MODAL} {
        ...TextBannerWithModalFragment
      }            
    }
  }
}
...on ${CONTENT_TYPES.PAGE_BODY_TEXT} {
  ...PageBodyTextFragment
}
...on ${CONTENT_TYPES.FEATURE_SPLIT} {
  ...FeatureSplitFragment
}
...on ${CONTENT_TYPES.BANNER_IN_PAGE} {
  ...BannerInPageFragment
}
... on ${CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER} {
  ...${CONTENT_TYPES.IMAGE_FULL_WIDTH_BANNER}Fragment
}
... on ${CONTENT_TYPES.IMAGE_BANNER_THREE_UP} {
  ...${CONTENT_TYPES.IMAGE_BANNER_THREE_UP}Fragment
}
...on ${CONTENT_TYPES.PAGE_SUMMERY_BLOCK} {
  ...${CONTENT_TYPES.PAGE_SUMMERY_BLOCK}Fragment
}
}  
}
`;
