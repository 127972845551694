import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps
} from '@chakra-ui/react';
import UpdatePasswordForm from './forms/UpdatePasswordForm';

const UpdatePasswordModal = ({
  onClose,
  isOpen,
  ...props
}: Omit<ModalProps, 'children'>): JSX.Element => {
  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" {...props}>
      <ModalOverlay />
      <ModalContent marginX={{ base: '3', md: '0' }}>
        <ModalHeader backgroundColor="primary.default" color="white" fontSize="md">
          Update Password
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody padding="1rem" marginBottom="0.5rem">
          <UpdatePasswordForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdatePasswordModal;
