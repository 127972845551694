import { CONTENT_TYPES } from 'utils/constants';

export const HEADER_OVERLAY_BANNER = `
  fragment HeaderOverlayBannerFragment on ${CONTENT_TYPES.HEADER_OVERLAY_BANNER} {
    __typename
    title
    imageOnlyBanner
    headline
    headlineColor
    text
    buttonText
    buttonColor
    buttonLink
    iconImage {
      ...Image
    }
  }
`;

export const HEADER_OVERLAY_BUTTON = `
  fragment HeaderOverlayButtonFragment on ${CONTENT_TYPES.HEADER_OVERLAY_BUTTON} {
    __typename
    title
    buttonText
    buttonColor
    buttonTextLink
  }
`;

export const HEADER_OVERLAY_TILES = `
  fragment ${CONTENT_TYPES.HEADER_OVERLAY_TILES}Fragment on ${CONTENT_TYPES.HEADER_OVERLAY_TILES} {
    __typename
    type
    tileCollection(limit: 6) {
      __typename
      items {
        title
        headlineH1
        subhead
        parentSlug
        slug
        iconThumbnail {
          url
          description
        }
        slug
      }
    }
  }
`;
