import { types as t, flow } from 'mobx-state-tree';
import * as api from '../api';

export const PrescriptionModel = t
  .model('Prescription', {
    id: t.identifier,
    rxid: t.string,
    din: t.string,
    patientKey: t.string,
    patientPin: t.string,
    pharmacyId: t.string,
    name: t.string,
    tradeName: t.maybe(t.string),
    genericName: t.maybe(t.string),
    fillDate: t.maybe(t.string),
    fillQuantity: t.maybe(t.string),
    strength: t.maybe(t.string),
    quantityRemaining: t.maybe(t.string),
    quantityAuthorized: t.maybe(t.string),
    image: t.maybe(t.string),
    doctorName: t.maybe(t.string),
    instructions: t.maybe(t.string),
    refillable: t.boolean,
    refillStatus: t.maybe(t.string),
    status: t.maybe(t.string),
    otherStatus: t.maybe(t.string),
    daysSupply: t.maybe(t.string),
    repeats: t.maybe(t.string),
    form: t.maybe(t.string),
    shortForm: t.maybe(t.string),
    longForm: t.maybe(t.string),
    patientPhone: t.maybe(t.string),
    expires: t.maybe(t.string),
    stateStatus: t.maybe(t.string),
    counsel: t.array(
      t.model('Counsel', {
        category: t.string,
        label: t.string,
        text: t.string
      })
    )
  })
  .volatile((self) => ({
    loadingDetail: true
  }))
  .actions((self) => {
    return {
      fetchDetail: flow(function* fetchPrescriptionDetail() {
        self.loadingDetail = true;

        const details = yield api.getPrescriptionDetail(
          self.patientKey,
          self.patientPin,
          self.pharmacyId,
          self.din
        );

        self.counsel = details.map((item: any) => ({
          category: item.Category,
          label: item.CategoryName,
          text: item.CounselText
        }));

        self.loadingDetail = false;
      }),

      updateAutoRefillStatus: flow(function* updateAutoRefillStatus({
        pharmacyPhone,
        patientPhone,
        rxNumber,
        autoRefillType
      }) {
        const result = yield api.updateAutoRefillStatus({
          pharmacyPhone: pharmacyPhone.replace(/\D/g, ''),
          patientPhone: patientPhone.replace(/\D/g, ''),
          rxNumber,
          autoRefillType
        });
      })
    };
  });

// example prescription response

// {
//   "objtype": "row",
//   "Filldate": "5/14/2019",
//   "Rxnumber": "1000472",
//   "Drugname": "Amlodipine (PS)",
//   "DrugStrength": "10mg",
//   "QuantityAuthorized": "150",
//   "FillQuantity": "30",
//   "QuantityRemaining": "90",
//   "Lastname": "Wade",
//   "Firstname": "Wilson",
//   "Doctor": "Hibbert,Julius",
//   "Instructions": "TAKE AS NEEDED",
//   "storeidentifier": "LONAB",
//   "rxid": "1000472",
//   "din": "2284073",
//   "drugpic": "https://testrx.londondrugs.com/centralprofile/images/drugpics/02284073.jpg",
//   "times": "",
//   "TradeName": "Amlodipine (PS)",
//   "GenericName": "amLODIPine",
//   "Refillable": "N",
//   "Reason": "This Prescription is on Order",
//   "dayssupply": "30",
//   "intervaldays": "",
//   "AuxLabel": "",
//   "AuxCodes": "",
//   "Repeats": "3",
//   "activeflag": "",
//   "AuthorizationStatus": "",
//   "Wb_status": "",
//   "Status": "",
//   "Gcn_seqno": "",
//   "tc": "",
//   "label1": "",
//   "label2": "",
//   "label3": "",
//   "labelnum1": "warnlab\\noimage.png",
//   "labelnum2": "warnlab\\noimage.png",
//   "labelnum3": "warnlab\\noimage.png",
//   "State": "4",
//   "StateImage": "rxstat/onorder.png",
//   "admintimes": "",
//   "adminseq": "",
//   "NHID": "",
//   "NHWARDID": "",
//   "nhname": "",
//   "wardname": "",
//   "drugform": "TAB (Tablet)",
//   "rxexpiry": "2020-10-02",
//   "origrxnum": "1000467",
//   "otherstatus": "",
//   "gender": "",
//   "Storescanned": "",
//   "Storeshipped": "",
//   "depotreceived": "",
//   "patientpickedup": "",
//   "inspays": "0",
//   "totrx": "0",
//   "patpays": "0"
// }

// example counselling detail response

// [
//   {
//     "objtype": "row",
//     "Category": "1",
//     "CategoryName": "Brand Names",
//     "CounselText": "Xeloda",
//     "Din": "02238453",
//     "Store": "LONAB"
//   }
// ]

// example store data from prescription list response

// {
//   "objtype": "row",
//   "Storekey": "1",
//   "Ip": "172.16.200.31",
//   "Port": "9002",
//   "Encryptionkey": "",
//   "Compression": "",
//   "Name": "LONDON DRUGS 022-Test 200",
//   "Address": "14951 STONY PLAIN RD NW",
//   "City": "EDMONTON",
//   "Province": "AB",
//   "Postal": "T5P 4W1",
//   "Country": "Canada",
//   "Bannerlink": "",
//   "Order": "",
//   "Counsel": "",
//   "CreatedDate": "8/22/2018 1:31:17 PM",
//   "ClinicalIp": "",
//   "ClinicalPort": "",
//   "StoreIdentifier": "LONAB",
//   "BannerImage": "",
//   "Pics": "Y",
//   "Phone": "780-483-8404",
//   "RxState1Image": "active.png",
//   "RxState2Image": "inactive.png",
//   "RxState3Image": "onfile.png",
//   "RxState4Image": "onorder.png",
//   "RxState5Image": "otc.png",
//   "SystemType": "KROLL",
//   "LabelShortForm": "7804838404",
//   "BannerId": "",
//   "RegionId": "",
//   "CorpStoreId": "022",
//   "RefillTitle": "",
//   "RefillNote": "",
//   "PickupDeliveryOptions": "P=Pickup|D=Local Delivery|C=Curbside|M=MailOrder",
//   "PickupDeliveryTimeslots": "~P=P4H|PICKUP-4 HOUR|240|It will be ready in 4 hours|The pharmacy will contact you when you prescriptions are ready for pickup.\r\n~P=PTOM|PICKUP-TOMORROW|1440| It will be ready the next business day| The pharmacy will contact you once your prescription is ready\r\n~P=P2day|PICKUP-2 Days Later|2880|It will be ready on the date you specified|The pharmacy will contact you once your prescription is ready\r\n~P=P3day|PICKUP-3 Days Later|4320|It will be ready on the date you specified|We will  contact you once your prescription is ready\r\n~P=P4day|PICKUP-4 Days Later|5760|Your order is successful.  It will be ready on the date you specified|The pharmacy will contact you once your prescription is ready\r\n~P=PCENT|Central Fill|240|It will be ready within 24 hours|Please wait until we call you before coming to the store as it may  take some time.\r\n~D=DTOM|Delivery|240|It will be delivered by the next business day|We will let you know when your delivery will arrive\r\n~C=D4H|Curbside|240|It will be ready in 4 hours|We will contact you when your order is ready for pickup\r\n~M=MTOM|Mail-Order|1440|It will be delivered by the next business day|We will let you know when your delivery will arrive"
// }
