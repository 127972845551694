import { UnknownObjectAny } from 'global.types';
import { types as t, flow } from 'mobx-state-tree';

export const SettingsStoreModel = t
  .model({
    showExtrasCardOnHomeScreen: false,
    showExtrasCardOnStart: false,
    selectedPharmacyId: t.maybe(t.string)
  })
  .actions((self: UnknownObjectAny) => ({
    toggleSetting: (settingName: any) => {
      self[settingName] = !self[settingName];
    },
    setSelectedPharmacyId: (id: string) => {
      self.selectedPharmacyId = id;
    },
    clearSelectedPharmacyId: () => {
      self.selectedPharmacyId = undefined;
    }
  }));
