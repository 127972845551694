import { CONTENT_TYPES } from 'utils/constants';

export const IMAGE_GRID_IN_PAGE = `
  fragment ImageGridInPageFragment on ${CONTENT_TYPES.IMAGE_GRID_IN_PAGE} {
    title
    headline
    headlineColor
    imagesCollection {
      items {
        title
        linkUrl
        image {
          ...Image
        }
        mobileImage {
          ...Image
        }
      }
    }
    sys {
      id
    }
  }
`;

export const COLUMNS_IN_PAGE = `
  fragment ColumnsInPageFragment on ${CONTENT_TYPES.COLUMNS_IN_PAGE} {
    title
    column1
    column2
    column3
    sys {
      id
    }
  }
`;

export const BUTTON_IN_PAGE = `
  fragment ButtonInPageFragment on ${CONTENT_TYPES.BUTTON_IN_PAGE} {
    title
    textColor
    bannerText
    buttonSize
    buttonSize
    buttonColor
    buttonAlignment
    linkUrl
    icon {
      title
      description
      url
    }
    sys {
      id
    }
  }
`;

export const VIDEO_WITH_THUMBNAIL = `
  fragment ${CONTENT_TYPES.VIDEO_WITH_THUMBNAIL}Fragment on ${CONTENT_TYPES.VIDEO_WITH_THUMBNAIL} {
    title
    videoThumbnail {
      title
      description
      url
      height
      width
    }
    headline
    headlineColor
    descriptionText
    bannerText
    textColor
    buttonColor
    linkUrl
    icon {
      title
      description
      url
    }
    sys {
      id
    }
  }
`;

export const BANNER_IN_PAGE = `
  fragment BannerInPageFragment on ${CONTENT_TYPES.BANNER_IN_PAGE} {
    title
    bannerText
    textColor
    linkText
    linkUrl
    bannerBackgroundColor
    width
    height
    margin
    sys {
      id
    }
  }
`;
