import { Box, Heading, Link } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import NextLink from 'next/link';

import ProxiedImage from 'components/common/ProxiedImage';
import { UnknownObjectAny } from 'global.types';
import { presetComponentProps } from 'theme';
import { ImageBannerData, ImageCMSData } from 'types/cms';

const ImageBanner3Up = ({
  bannersCollection,
  backgroundColor,
  headline,
  headlineColor
}: ImageBannerData): ReactElement => {
  const imageBannerCollection = [...bannersCollection?.items]?.splice(0, 6);

  const BannerImage = (image: ImageCMSData) => (
    <ProxiedImage
      src={image?.url}
      width={image?.width}
      height={image?.height}
      alt={image?.description}
    />
  );

  return (
    <Box
      d="flex"
      justifyContent="center"
      flexDir="column"
      textAlign="center"
      px={{ base: '1rem', md: '4rem', lg: '6rem', xl: '10rem' }}
      py="2rem"
      bgColor={backgroundColor ?? 'shades.300'}
      w="100%"
    >
      {headline && (
        <Heading
          {...presetComponentProps.h3}
          mb="1.5rem"
          color={headlineColor ?? 'primary.deafult'}
        >
          {headline}
        </Heading>
      )}
      <Box d="flex" flexWrap="wrap" justifyContent="space-around">
        {imageBannerCollection?.map((item: UnknownObjectAny) => (
          <Box
            key={item?.title}
            d="flex"
            justifyContent="center"
            alignItems="center"
            w={{
              base: '100%',
              sm: '47%',
              md:
                imageBannerCollection?.length >= 3 && imageBannerCollection?.length !== 4
                  ? '32%'
                  : imageBannerCollection?.length === 1
                  ? '100%'
                  : '47%'
            }}
            h="auto"
            bgColor="transparent"
            mb={{ base: '1rem', md: imageBannerCollection?.length >= 4 ? '1rem' : '0' }}
          >
            {item?.linkUrl ? (
              <NextLink href={item?.linkUrl} passHref>
                <Link as="a" aria-label={item?.title}>
                  <BannerImage {...item?.image} />
                </Link>
              </NextLink>
            ) : (
              <BannerImage {...item?.image} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageBanner3Up;
