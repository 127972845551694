import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  Flex,
  Icon,
  Slide,
  Text,
  useDisclosure,
  Image
} from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowUpIcon, ChevronLeftIcon, HamburgerIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { UnknownObjectAny } from 'global.types';
import photoLabLogo from '../../public/assets/photo-lab-icon.png';
import { useStore } from 'store';
import LDLink from 'components/common/LDLink';
import { Banner } from 'components/CMS';
import { PRESCRIPTION_PROFILE_URL } from 'utils/constants';
import ProxiedImage from 'components/common/ProxiedImage';
import SearchBar from 'components/algolia/SearchBar';

const AccountIcon = (props: UnknownObjectAny) => (
  <FontAwesomeIcon icon={['fas', 'user']} {...props} />
);

const MobileHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentNavigation, setCurrentNavigation] = useState<string | undefined>();
  const { cmsStore, patientStore } = useStore();
  const router = useRouter();

  const { globalHeaderBanner, mainNavigation } = cmsStore;

  const subNavigation = useMemo(() => {
    return mainNavigation?.find(({ title }: { title: string }) => title === currentNavigation)
      ?.subcategoriesCollection?.items;
  }, [currentNavigation, mainNavigation]);

  return (
    <Box display={{ md: 'none' }}>
      {globalHeaderBanner && <Banner {...globalHeaderBanner} mobile isGlobalHeaderBanner />}
      <Flex
        backgroundColor="primary.default"
        paddingX={{ base: '10', sm: '5' }}
        alignItems="center"
        justifyContent="center"
        minH="90"
      >
        <Box flex={1}>
          <NextLink href="/" passHref>
            <a>
              <Image
                src="/assets/pharmacy-logo.svg"
                width={419}
                height={91}
                alt="London Drugs logo"
              />
            </a>
          </NextLink>
        </Box>
      </Flex>
      <Flex backgroundColor="primary.dark" padding="3" alignItems="center">
        <HamburgerIcon onClick={onOpen} color="white" w={9} h={9} marginRight={8} />
        <Box flex={2}>
          <SearchBar />
        </Box>
      </Flex>
      <Drawer isOpen={isOpen} placement="left" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="white" size="lg" />
          <DrawerHeader backgroundColor="primary.default" paddingX="4" paddingY="5">
            {patientStore?.isLoggedIn ? (
              <Text
                as="button"
                color="white"
                fontSize="md"
                onClick={() => {
                  patientStore?.logout();
                  onClose();
                  router.push(PRESCRIPTION_PROFILE_URL);
                }}
              >
                Sign Out
              </Text>
            ) : (
              <LDLink
                href={PRESCRIPTION_PROFILE_URL}
                onClick={() => {
                  onClose();
                  setCurrentNavigation(undefined);
                }}
                linkText="Sign In"
                color="white"
                fontSize="md"
              />
            )}
          </DrawerHeader>

          {!currentNavigation ? (
            <DrawerBody padding="0">
              {mainNavigation?.map(({ title }: { title: string }, index: number) => {
                return (
                  <Flex
                    key={`mobile-nav-${index}`}
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="1px solid #C6C6C6"
                    paddingX="5"
                    paddingY="4"
                    onClick={() => setCurrentNavigation(title)}
                  >
                    <Text fontSize="lg" fontWeight="medium" color="primary.dark">
                      {title}
                    </Text>
                    <ArrowForwardIcon color="primary.dark" w={6} h={6} />
                  </Flex>
                );
              })}
              <Flex
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #C6C6C6"
                paddingX="5"
                paddingY="4"
                onClick={() => {
                  if (patientStore?.isLoggedIn) {
                    router.push(PRESCRIPTION_PROFILE_URL);
                    onClose();
                  } else {
                    router.push(PRESCRIPTION_PROFILE_URL);
                    onClose();
                  }
                }}
              >
                <Flex alignItems="center">
                  <Icon as={AccountIcon} color="primary.dark" marginRight={3} />
                  <Text fontSize="lg" fontWeight="medium" color="primary.dark">
                    My Account
                  </Text>
                </Flex>
                <ArrowForwardIcon color="primary.dark" w={6} h={6} />
              </Flex>
              {[
                { title: 'Shop LD.com', href: 'https://www.londondrugs.com/' },
                { title: 'PhotoLab', href: 'https://photolab.londondrugs.com/' },
                { title: 'LDExtras', href: 'https://www.ldextras.com/' }
              ].map(({ title, href }, index: number) => (
                <a href={href} target="_blank" rel="noreferrer" key={`mobile-nav-links-${index}`}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="1px solid #C6C6C6"
                    paddingX="5"
                    paddingY="4"
                    backgroundColor="shades.50"
                  >
                    {title === 'LDExtras' ? (
                      <Text color="primary.dark" fontWeight="medium">
                        LD
                        <Text as="span" fontStyle="italic" color="accent.ldExtras">
                          Extras
                        </Text>
                      </Text>
                    ) : title === 'PhotoLab' ? (
                      <Flex alignItems="center">
                        <ProxiedImage
                          src={photoLabLogo}
                          width={16}
                          height={17}
                          layout="fixed"
                          alt="PhotoLab logo"
                        />
                        <Text marginLeft="1" fontSize="lg" fontWeight="medium" color="primary.dark">
                          {title}
                        </Text>
                      </Flex>
                    ) : (
                      <Text fontSize="lg" fontWeight="medium" color="primary.dark">
                        {title}
                      </Text>
                    )}

                    <ArrowUpIcon
                      color="primary.dark"
                      w={6}
                      h={6}
                      style={{ transform: 'rotate(45deg)' }}
                    />
                  </Flex>
                </a>
              ))}
              <Box paddingX="5" paddingTop="5">
                <a
                  href="https://www.londondrugs.com/store-flyers/eflyer.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text fontSize="md">Flyers</Text>
                </a>
              </Box>
              <Box paddingX="5" paddingTop="5">
                <a href="https://www.londondrugs.com/locations" target="_blank" rel="noreferrer">
                  <Text fontSize="md">Locations</Text>
                </a>
              </Box>
              <Box paddingX="5" paddingTop="5">
                <Text fontSize="md">
                  Customer Service:{' '}
                  <a
                    href="tel:18889912299"
                    style={{ color: 'primary.dark', textDecoration: 'underline' }}
                  >
                    1-888-991-2299
                  </a>
                </Text>
              </Box>
            </DrawerBody>
          ) : (
            <DrawerBody padding="0">
              <Slide
                in={!!currentNavigation}
                direction="left"
                style={{ position: 'unset' }}
                unmountOnExit
              >
                <Flex
                  alignItems="center"
                  borderBottom="1px solid #C6C6C6"
                  paddingX="5"
                  paddingY="4"
                  backgroundColor="shades.50"
                  onClick={() => setCurrentNavigation(undefined)}
                >
                  <ChevronLeftIcon color="primary.default" w={8} h={8} />
                  <Text
                    flex={1}
                    textAlign="center"
                    color="primary.dark"
                    fontWeight="medium"
                    fontSize="lg"
                  >
                    {currentNavigation}
                  </Text>
                </Flex>
                {subNavigation?.map(
                  (
                    {
                      title,
                      route,
                      textColor
                    }: { title: string; route: string; textColor?: string },
                    index: number
                  ) => (
                    <NextLink key={`sub-navigation-item-${index}`} href={route} passHref>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid #C6C6C6"
                        paddingX="5"
                        paddingY="4"
                        onClick={() => {
                          onClose();
                          setCurrentNavigation(undefined);
                        }}
                      >
                        <Text fontSize="lg" fontWeight="medium" color={textColor || 'primary.dark'}>
                          {title}
                        </Text>
                        <ArrowForwardIcon color={textColor || 'primary.dark'} w={6} h={6} />
                      </Flex>
                    </NextLink>
                  )
                )}
              </Slide>
            </DrawerBody>
          )}
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default observer(MobileHeader);
