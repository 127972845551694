import { ReactElement } from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { UnknownObjectAny } from 'global.types';
import { CONTENT_TYPES } from 'utils/constants';
import { ComponentGeneratorProps } from './ComponentGenerator';
import { LocationBanner, Pharmacist, TextBanner, TextBannerWithModal } from 'components/CMS';
import { presetComponentProps } from 'theme';
import { useRouter } from 'next/router';

export interface SideBySideBannersProps extends UnknownObjectAny {
  title: string;
  headline?: string;
  headlineColor?: string;
  bannersCollection: {
    items: Array<ComponentGeneratorProps>;
  };
}

const renderBanner = (props: ComponentGeneratorProps, index: number) => {
  switch (props.__typename) {
    case CONTENT_TYPES.PHARMACIST:
      return <Pharmacist key={`pharmacist-${index}`} {...props} />;
    case CONTENT_TYPES.TEXT_BANNER_WITH_MODAL:
      return (
        <TextBannerWithModal
          key={`text-banner-with-modal-${index}`}
          {...props}
          headline={props?.headline}
          modalText={props?.modalText}
        />
      );
    case CONTENT_TYPES.LOCATION_BANNER:
      return (
        <LocationBanner
          key={`location-banner-${index}`}
          {...props}
          storeLocation={props.storeLocation}
        />
      );
    case CONTENT_TYPES.TEXT_BANNER:
      return <TextBanner key={`text-banner-${index}`} {...props} headline={props.headline} />;
    default:
      return null;
  }
};

const headlineRegex =
  /^(How to get started on your caregiver support journey|Locations|(Asymptomatic COVID-19 Testing Clinic Locations).+)$/i;
const pathRegex =
  /^(\/health-clinics\/fertility-health|\/pharmacy-services\/teva-caregiver-support-program|\/covid-19-information\/alberta|\/covid-19-information\/british-columbia)(#book|$)$/;

const SideBySideBanners = ({
  bannersCollection,
  headline,
  headlineColor
}: SideBySideBannersProps): ReactElement => {
  const { asPath } = useRouter();
  // https://64labs.atlassian.net/browse/LDP-137 & https://64labs.atlassian.net/browse/LDP-143: Need to insert an id for deep linking to the Locations section of various pages
  const shouldHaveBookHash = headline && headlineRegex.test(headline) && pathRegex.test(asPath);

  return (
    <>
      {headline && (
        <Box d="flex" justifyContent="center" mb={{ base: '1rem', lg: '2rem' }}>
          <Heading
            {...presetComponentProps?.h2}
            color={headlineColor || 'primary.default'}
            id={shouldHaveBookHash ? 'book' : ''}
          >
            {headline}
          </Heading>
        </Box>
      )}
      <Box
        display="grid"
        gridTemplateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          lg: bannersCollection?.items?.length === 2 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
        }}
        justifyContent="center"
        gridColumnGap={{ base: '4', md: '8' }}
        gridRowGap={{ base: '4', md: '8' }}
        maxWidth="100%"
        mb="1.5rem"
      >
        {bannersCollection?.items?.map((item, index) =>
          renderBanner(
            { ...item, isTwoUp: bannersCollection?.items?.length === 2 ? true : false },
            index
          )
        )}
      </Box>
    </>
  );
};

export default SideBySideBanners;
