import shajs from 'sha.js';
import {
  SEND_REQUEST_TO_PHYSICIAN,
  ORDERED_STATUS,
  WILL_CONTACT_DOCTOR,
  NOTE_PREFIX,
  INPUT_ERROR_STATUSES,
  GENERIC_ERROR_STATUSES,
  SUCCESS_STATUSES
} from './constants';

export const sha256 = (pin: string) => {
  return shajs('sha256').update(pin).digest('hex').toUpperCase();
};

export const serialize = (data: any) => {
  const uriComponents: any[] = [];
  Object.keys(data).forEach((key) =>
    uriComponents.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  );
  return uriComponents.join('&');
};

export const isRxWithDocRequest = (amjayItem: any) => {
  if (!amjayItem.Message) {
    return false;
  }

  // This is from EH's original code
  if (SEND_REQUEST_TO_PHYSICIAN.test(amjayItem.Message)) {
    return true;
    // The following conditions are added to make the check more robust
  } else if (amjayItem.Status === ORDERED_STATUS && WILL_CONTACT_DOCTOR.test(amjayItem.Message)) {
    return true;
  } else if (amjayItem.Status === ORDERED_STATUS && NOTE_PREFIX.test(amjayItem.Message)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Determines if the Rx item response from Amjay represents an error or a
 * warning.
 *
 * @param {Object} amjayItem An "OrderItem" row from Amjay.
 */
export const classifyRxItem = (amjayItem: any) => {
  // Differentiate between "input" and "generic" errors.
  // - Input errors *can* cause the user to stay on the quick refill page
  //   if no other orders succeeded.
  // - Generic errors will be marked as errors, but the user will still
  //   proceed to the refill confirmation page.
  const isInputError = INPUT_ERROR_STATUSES.indexOf(amjayItem.Status) !== -1;
  const isGenericError = GENERIC_ERROR_STATUSES.indexOf(amjayItem.Status) !== -1;
  const isError = isInputError || isGenericError;

  const isSuccess = SUCCESS_STATUSES.indexOf(amjayItem.Status) !== -1;
  const hasDocRequest = isRxWithDocRequest(amjayItem);

  // If the status wasn't an error AND wasn't a success, mark it as a
  // warning. If it was successful but has a doctor request, mark it as a
  // warning too.
  const isWarning = (!isError && !isSuccess) || hasDocRequest;

  return {
    isError,
    isInputError,
    isGenericError,
    isWarning
  };
};

/**
 * LDP-107 The amount of supply a user orders cannot exceed 100 days.
 * This function determines that based on the daily supply of the default quantity combined with the currently entered custom quantity
 * @param {string} daysSupply The amount of days the default quantity of a prescription will last for (provided by Amjay)
 * @param {string} currentQuantity The currently entered quantity for a refill (Initially equals defaultFillQuantity but can be changed by the user)
 * @param {string} defaultFillQuantity The default fill quantity of a prescription (provided by Amjay)
 * @returns {boolean}
 */
export const determineDaysSupplyValidity = (
  daysSupply: string,
  currentQuantity: string,
  defaultFillQuantity: string
): boolean => {
  const currentDaysSupply =
    parseInt(daysSupply) * (parseInt(currentQuantity) / parseInt(defaultFillQuantity));
  return !isNaN(currentDaysSupply) ? currentDaysSupply <= 100 : true;
};
