import { Box, Text, useTheme } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import Link from 'next/link';
import RightArrow from '../../public/assets/icons/right-arrow.svg';
import { BannerCMSData } from 'types/cms';
import { colorExtractor, LightenDarkenColor } from 'utils';

export interface BannerProps extends BannerCMSData {
  isGlobalHeaderBanner?: boolean;
  isPageAlertBanner?: boolean;
  inPageBanner?: boolean;
  mobile?: boolean;
}

const Banner = ({
  bannerText,
  linkText,
  linkUrl,
  bannerBackgroundColor,
  isGlobalHeaderBanner,
  isPageAlertBanner,
  textColor,
  inPageBanner,
  width,
  margin,
  height
}: BannerProps): ReactElement => {
  const theme = useTheme();
  const defaultBannerBackgroundColor = isGlobalHeaderBanner ? 'accent.default' : 'shades.50';
  const backgroundColor = colorExtractor(bannerBackgroundColor, defaultBannerBackgroundColor);
  const defaultTextColor = isGlobalHeaderBanner ? 'white' : 'primary.medium';
  const bannerTextColor = colorExtractor(textColor, defaultTextColor);

  const maxWidthValue = () => {
    if (isGlobalHeaderBanner || isPageAlertBanner) return '100%';

    if (inPageBanner)
      return width === 'Full-width'
        ? theme?.sizes?.container?.xxl
        : theme?.sizes?.container?.standard;

    return theme?.sizes?.container?.standard;
  };

  const bannerHeight = () => {
    if (isGlobalHeaderBanner) {
      return {
        base: isGlobalHeaderBanner ? '40px' : '60px',
        md: isGlobalHeaderBanner ? '40px' : '60px',
        lg: isGlobalHeaderBanner ? '50px' : '80px'
      };
    }

    if (inPageBanner) {
      return {
        base: height === 'Standard' ? '60px' : '40px',
        lg: height === 'Standard' ? '80px' : '50px'
      };
    }

    return {
      base: '60px',
      lg: '80px'
    };
  };

  // isGlobalHeaderBanner ? 2.5 : 6
  const bannerPaddingY = () => {
    if (isGlobalHeaderBanner) {
      return 2.5;
    }

    if (inPageBanner && height === 'Thin') {
      return { base: 2, md: 2 };
    }

    return 6;
  };

  const margins = () => {
    switch (margin) {
      case 'Top':
        return '2.5rem 0 0  0';
      case 'Bottom':
        return '0 0 2.5rem 0';
      case 'Top & Bottom':
        return '2.5rem 0';
      default:
        return undefined;
    }
  };

  const BannerContent = () => (
    <Box display="flex" justifyContent="center" alignItems="center" h="full">
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Text
          fontSize={
            isGlobalHeaderBanner ? { base: 'sm', md: 'md' } : { base: 'md', sm: 'lg', lg: 'xl' }
          }
          fontWeight={isGlobalHeaderBanner ? 'medium' : 'bold'}
          lineHeight={isGlobalHeaderBanner ? { base: 1, md: 1.5 } : { base: 1.375, md: 1.5 }}
          color={bannerTextColor}
        >
          {bannerText}
          {linkUrl &&
            (linkText ? (
              <Box as="span" d={isGlobalHeaderBanner ? { base: 'none', md: 'inline' } : 'inline'}>
                {' | '}
                <Text as="span" textDecoration="underline" color={bannerTextColor}>
                  {linkText}
                </Text>
              </Box>
            ) : (
              <Box
                as="span"
                d={isGlobalHeaderBanner ? 'none' : 'inline-block'}
                ml={3}
                w={{ base: 4, md: 4, lg: 5 }}
                h={{ base: 4, md: 4, lg: 5 }}
                style={{ verticalAlign: 'middle' }}
                pb={0.5}
              >
                <RightArrow width="100%" height="100%" fill={bannerTextColor} />
              </Box>
            ))}
        </Text>
      </Box>
    </Box>
  );

  return (
    <>
      {linkUrl ? (
        <Link href={linkUrl} passHref>
          <Box
            as="a"
            width="100%"
            mx="auto"
            maxW={maxWidthValue()}
            minH={bannerHeight()}
            borderRadius={inPageBanner ? 'xl' : undefined}
            px="4"
            py={bannerPaddingY()}
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={backgroundColor}
            _hover={{ bgColor: LightenDarkenColor(backgroundColor, -10) }}
            m={margins()}
          >
            <BannerContent />
          </Box>
        </Link>
      ) : (
        <Box
          width="100%"
          mx="auto"
          maxW={maxWidthValue()}
          minH={bannerHeight()}
          borderRadius={inPageBanner ? 'xl' : undefined}
          px="4"
          py={{
            base: isGlobalHeaderBanner ? 2.5 : 6
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={backgroundColor}
          m={margins()}
        >
          <BannerContent />
        </Box>
      )}
    </>
  );
};

export default Banner;
