import { Container, ContainerProps } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import { presetComponentProps } from 'theme';

export interface PageContentContainerProps
  extends Omit<ContainerProps, 'maxW' | 'marginBottom' | 'padding'> {
  isFullWidth?: boolean;
}

const PageContentContainer = ({
  children,
  isFullWidth,
  ...props
}: PageContentContainerProps): ReactElement => {
  const fullWidthStyles = {
    maxW: 'container.xl',
    d: 'flex',
    justifyContent: 'center',
    ...presetComponentProps?.pageContainer,
    ...props
  } as ContainerProps;

  const InnerPageWidthStyles = {
    ...presetComponentProps?.pageContainer,
    maxW: 'container.standard',
    marginBottom: { base: '4', md: '12', xl: '2rem' },
    mx: 'auto',
    ...props
  } as ContainerProps;

  return (
    <Container {...(isFullWidth ? fullWidthStyles : InnerPageWidthStyles)}>{children}</Container>
  );
};

export default PageContentContainer;
