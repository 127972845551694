import { UnknownObjectAny } from 'global.types';
import {
  footerComplianceNavigationQuery,
  footerNavigationQuery,
  globalHeaderBannerQuery,
  mainNavigationQuery
} from 'graphql/queries/global';
import {
  GlobalHeaderBannerQueryData,
  LinksData,
  MainNavigationQueryItemCollection,
  NavLinkData
} from 'types/cms';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';

const spaceId = process.env.CONTENTFUL_SPACE_ID;
const apiKey = process.env.CONTENTFUL_API_KEY;
const previewApiKey = process.env.CONTENTFUL_PREVIEW_API_KEY;

const fetchContentfulData = async (
  data: {
    query: string;
    variables?: UnknownObjectAny;
  },
  preview = false
): Promise<UnknownObjectAny | unknown> => {
  try {
    const response = await fetch(`https://graphql.contentful.com/content/v1/spaces/${spaceId}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${preview ? previewApiKey : apiKey}`
      },
      body: JSON.stringify({
        // Trimming whitespace and removing newline characters to help keep query size from getting too large
        query: data.query.replace(/\r?\n|\r/g, '').trim(),
        variables: data?.variables
      })
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('ERROR FETCHING CONTENTFUL DATA FROM GRAPHQL API');
    console.error(error);
    return error;
  }
};

export interface GlobalAppData {
  globalHeaderBanner: GlobalHeaderBannerQueryData;
  mainNavigation: MainNavigationQueryItemCollection;
  footerNavigation: { footerNav: NavLinkData[]; complianceNav: LinksData[] };
}

export const fetchGlobalAppData = async (): Promise<GlobalAppData> => {
  const bannerResponse = (await fetchContentfulData({
    query: globalHeaderBannerQuery
  })) as UnknownObjectAny;
  const navigationResponse = (await fetchContentfulData({
    query: mainNavigationQuery
  })) as UnknownObjectAny;
  const footerNavigationResponse = (await fetchContentfulData({
    query: footerNavigationQuery
  })) as UnknownObjectAny;
  const footerComplianceNavigationResponse = (await fetchContentfulData({
    query: footerComplianceNavigationQuery
  })) as UnknownObjectAny;

  graphQLErrorHandler('bannerResponse', bannerResponse?.errors);
  graphQLErrorHandler('navigationResponse', navigationResponse?.errors);
  graphQLErrorHandler('footerNavigationResponse', footerNavigationResponse?.errors);
  graphQLErrorHandler(
    'footerComplianceNavigationResponse',
    footerComplianceNavigationResponse?.errors
  );

  const footerNavigation = {
    footerNav: footerNavigationResponse?.data?.list?.itemCollection?.items?.filter(
      (navList: UnknownObjectAny) => {
        return navList?.title !== footerComplianceNavigationResponse?.data?.navCategoryItem?.title;
      }
    ),
    complianceNav:
      footerComplianceNavigationResponse?.data?.navCategoryItem?.subcategoriesCollection?.items
  };

  return {
    footerNavigation,
    globalHeaderBanner: bannerResponse?.data?.list?.itemCollection?.items?.[0],
    mainNavigation: navigationResponse?.data?.list?.itemCollection?.items
  };
};

export default fetchContentfulData;
