import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { ReactElement } from 'react';

import { PharmacistCMSData } from 'types/cms';
import { PageBodyText } from '.';
import ProxiedImage from 'components/common/ProxiedImage';
import useModalPageViewEventHandler from 'hooks/useModalPageViewEventHandler';

// Needs CMS data on a page to complete
const Pharmacist = ({
  name,
  jobTitle,
  pharmacyTitle,
  certifications,
  email,
  phone,
  languages,
  location,
  image,
  bio
}: PharmacistCMSData): ReactElement => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width:767px )');
  useModalPageViewEventHandler({
    modalIsOpen: isOpen,
    modalName: 'Pharmacist'
  });

  return (
    <Box border="1px solid #AFAFAF" padding="8" as="button" onClick={onOpen}>
      <Stack alignItems="center" spacing="3" height="100%">
        {image && (
          <ProxiedImage src={image.url} width={image.width} height={image.height} alt={name} />
        )}
        <Box>
          {pharmacyTitle && (
            <Text fontSize="lg" color="primary.default">
              {pharmacyTitle}
            </Text>
          )}
          {name && (
            <Text fontSize="2xl" fontWeight="bold" color="primary.default">
              {name}
            </Text>
          )}
          {jobTitle && (
            <Text fontSize="lg" color="primary.default">
              {jobTitle}
            </Text>
          )}
        </Box>
        <AddIcon width={6} height={6} />
      </Stack>
      <Modal isCentered onClose={onClose} isOpen={isOpen} size={isMobile ? 'full' : '4xl'}>
        <ModalOverlay />
        <ModalContent marginX={{ base: '0', md: '2' }}>
          <ModalCloseButton />
          <ModalBody padding={{ base: '3', md: '8' }}>
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'center', md: 'unset' }}
              justifyContent="center"
            >
              {image && (
                <Box marginRight={{ base: '0', md: '2.5' }} marginBottom="2.5" flex={1}>
                  <ProxiedImage
                    src={image.url}
                    width={image.width}
                    height={image.height}
                    alt={name}
                  />
                </Box>
              )}
              <Stack flex={3}>
                <Text fontWeight="bold" color="primary.default">
                  {name}
                </Text>
                {bio && (
                  <PageBodyText
                    data={{
                      pageBodyText: { ...bio, links: {} },
                      title: '',
                      __typename: ''
                    }}
                  />
                )}
                <Box>
                  {location && (
                    <Text>
                      <Text as="span" fontWeight="bold">
                        Location:
                      </Text>{' '}
                      {location}
                    </Text>
                  )}
                  {languages && (
                    <Text>
                      <Text as="span" fontWeight="bold">
                        Languages:
                      </Text>{' '}
                      {languages}
                    </Text>
                  )}
                  {phone && (
                    <Text>
                      <Text as="span" fontWeight="bold">
                        Phone:
                      </Text>{' '}
                      {phone}
                    </Text>
                  )}
                  {email && (
                    <Text>
                      <Text as="span" fontWeight="bold">
                        Email:
                      </Text>{' '}
                      {email}
                    </Text>
                  )}
                  {certifications && (
                    <Text>
                      <Text as="span" fontWeight="bold">
                        Certifications:
                      </Text>{' '}
                      {certifications}
                    </Text>
                  )}
                </Box>
              </Stack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Pharmacist;
