import { CONTENT_TYPES } from 'utils/constants';

const FEATURE_SPLIT = `
    fragment FeatureSplitFragment on ${CONTENT_TYPES.FEATURE_SPLIT} {
        title
        image {
            ...Image
        }
        imageLocation
        headline
        headlineColor
        text
        textColor
        buttonText
        buttonTextLink
        buttonColor
    }
`;

export default FEATURE_SPLIT;
