import IMask from 'imask';
import moment from 'moment';

export const isInitialSiteLoad = (url: string | undefined): boolean =>
  typeof url === 'string' && !/(^\/_next).+(\.json$)/.test(url.split('?')[0]);

export function formatDate(date: Date | string) {
  if (date) {
    return moment(date).format('MMM D, YYYY');
  }
  return null;
}

export const colorExtractor = (color: string | undefined, defaultColor: string) => {
  return typeof color === 'string' ? color.substring(color.indexOf('#')) : defaultColor;
};

// Used to convert hex color code into hsl. You can adjust the lightness or darkness of the color that is passed in as the second parameter. Original use was for hover on buttons.
// hexColor: The color as a hex value
// amount: Is added to the current value of L. A negative value will subject from the lightness value.
export function LightenDarkenColor(hexColor: string, amount: number) {
  const H = hexColor;

  // Convert hex to RGB first
  let r: any = 0,
    g: any = 0,
    b: any = 0;
  if (H?.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H?.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);
  const lightness = l + amount;

  return 'hsl(' + h + ',' + s + '%,' + lightness + '%)';
}

export const phoneMask = IMask.createMask({
  mask: '{+1} (000) 000-0000'
});

export const getMaskedPhoneValue = (value: string | undefined) => {
  if (!value) return value;

  phoneMask.resolve(value as string);

  return phoneMask.value;
};

export const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

export const urlForwardSlashCleaner = (url: string | undefined) => {
  if (!url) return;

  return (url?.charAt(0) === '/' || url.startsWith('http') ? url : `/${url}`)
    .replace(/([^:]\/)\/+/g, '$1')
    .replace(/\\/g, '/');
};

export const capitalizeAllWordsInString = (string: string): string => {
  const words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};
